<template>
  <div class="portal-header">
    <div class="portal-header__container">
      <a-icon
        type="menu"
        class="portal-header__menu-switch"
        @click="openMenuDrawer"
      />
      <div class="portal-header__left portal-header__left--pc">
        <op-site-logo height="50" />
        <div
          class="portal-header__left__menu"
          :selected-keys="selectedKeys"
          mode="horizontal"
        >
          <div
            v-for="menu in menus"
            :key="menu.path"
            :class="[
              'portal-header__left__menu__item',
              menu.path === $route.path || (menu.meta.menuHighlightPath || []).includes($route.path)
                ? 'portal-header__left__menu__item--active'
                : '',
            ]"
            @click="clickMenu(menu.path)"
          >
            <a-dropdown v-if="menu.path === '/portal/blog-list'">
              <a-dropdown>
                <span @click="e => e.preventDefault()">{{ menu.meta.title }}</span>
                <a-menu slot="overlay">
                  <a-menu-item
                    v-for="item in blogCategoryList"
                    :key="item.id"
                    @click="goBlogList(item.id)"
                  >
                    <a href="javascript:;">{{ item.categoryName }}</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-dropdown>
            <span v-else>{{ menu.meta.title }}</span>
          </div>
        </div>
      </div>
      <a-drawer
        placement="left"
        :visible="menuDrawerVisible"
        width="60%"
        wrap-class-name="portal-header__drawer"
        @close="closeMenu"
      >
        <div class="portal-header__left portal-header__left--mobile">
          <op-site-logo
            height="38"
            class="portal-header__left--mobile__logo"
          />
          <a-menu
            class="portal-header__left__menu"
            :selected-keys="selectedKeys"
            mode="inline"
            @click="mobileClickMenu"
          >
            <a-menu-item
              v-for="menu in menus"
              :key="menu.path"
            >{{ menu.meta.title }}</a-menu-item>
          </a-menu>
        </div>
      </a-drawer>
      <div class="portal-header__action">
        <div
          v-if="!isPandovaApp() && !userInfo.userId"
          class="portal-header__action__log-in"
          @click="goLogin"
        >{{ $t('portal.commonHeader.login') }}</div>
        <div
          v-if="!isPandovaApp()"
          class="portal-header__action__start"
        >
          <a-button
            class="portal-header__action__start__button"
            @click="goStart"
          >{{ startText }}</a-button>
        </div>
        <div class="portal-header__action__language">
          <op-language-dropdown />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPortalLayoutRoutes } from '@/router/router.config'
import { LANGUAGE_TYPE_TEXT_MAP_LIST, LANGUAGE_TYPE_TEXT_MAP } from '@/constants/common'
import { mapState, mapMutations } from 'vuex'
import { getBlogCategoryList } from '@/api/portal'
import { PortalAnalytics, isPandovaApp } from '@/utils'
export default {
  name: 'PortalHeader',
  components: {},
  data() {
    return {
      current: 'portal-home',
      LANGUAGE_TYPE_TEXT_MAP_LIST,
      menuDrawerVisible: false,
      blogCategoryList: [],
      isPandovaApp,
    }
  },
  computed: {
    ...mapState(['userInfo', 'language']),
    selectedKeys() {
      return [this.$route.path]
    },
    menus() {
      const routes = getPortalLayoutRoutes().find(item => item.path === '')
      return ((routes && routes.children) || []).filter(item => !item.hidden)
    },
    startText() {
      return this.userInfo.userId
        ? $t('portal.commonHeader.myStore')
        : $t('portal.commonHeader.freeStart')
    },
    languageLabel() {
      return LANGUAGE_TYPE_TEXT_MAP[this.language]
    },
  },
  created() {
    this.getBlogCategoryList()
  },
  methods: {
    ...mapMutations(['setLanguage']),
    async getBlogCategoryList() {
      const res = await getBlogCategoryList()
      this.blogCategoryList = res.data
    },
    goLogin() {
      this.$router.push({ path: '/account/login' })
    },
    goStart() {
      const path = this.userInfo.userId ? '/home' : '/account/register'
      if (!this.userInfo.userId) {
        PortalAnalytics.mc('spportal_home_getstartedbutton_click', this.$route.query)
        this.$gtag.event('spportal_home_getstartedbutton_click', {
          event_category: 'portal',
          event_label: 'home',
          ...this.$route.query,
        })
        this.$analytics.fbq.event('AddToWishlist', {
          content_category: 'portal',
          content_name: 'home',
          ...this.$route.query,
        })
      }
      this.$router.push({ path })
    },
    clickMenu(path) {
      this.closeMenu()
      this.$router.push({ path })
    },

    handleChangeLanguage(language) {
      this.setLanguage(language)
      const serviceTermsRoutePath = '/portal/service-terms'
      const privacyPolicyRoutePath = '/portal/privacy-policy'
      const currentRoutePath = this.$route.path
      if ([serviceTermsRoutePath, privacyPolicyRoutePath].includes(currentRoutePath)) {
        window.location.replace(`${currentRoutePath}?languageCode=${language}`)
      } else {
        window.location.reload()
      }
    },
    openMenuDrawer() {
      this.menuDrawerVisible = true
    },
    closeMenu() {
      this.menuDrawerVisible = false
    },
    goBlogList(categoryId) {
      this.$router.push({ path: '/portal/blog-list', query: { categoryId: categoryId } })
    },
    mobileClickMenu({ path, key }) {
      this.$router.push({ path: key })
      this.closeMenu()
    },
  },
}
</script>

<style lang="less" scoped>
.portal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 95px;
  box-shadow: 0 1px 6px 0 rgba(0, 21, 41, 0.12);
  &__menu-switch {
    display: none;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    height: 100%;
    padding: 25px 100px;
    margin: 0 auto;
  }
  &__left {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;
    &__logo {
      height: 50px;
      cursor: pointer;
    }
    &__menu {
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0 30px;
      &__item {
        padding: 0 14px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        &--active {
          color: rgba(0, 184, 118, 1);
        }
        &:hover {
          color: rgba(0, 184, 118, 1);
        }
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__log-in,
    &__language {
      font-size: 16px;
      font-weight: 500;
      color: rgba(1, 4, 13, 1);
      cursor: pointer;
    }
    &__start__button {
      height: 52px;
      padding: 0 40px;
      margin: 0 12px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      background: rgba(0, 184, 118, 1);
      border-radius: 34px;
    }
    &__language {
      &__text {
        margin: 0 5px;
      }
    }
  }
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 1260px) {
  .portal-header {
    min-height: 64px;
    &__left--pc {
      display: none;
    }
    &__menu-switch {
      display: block;
      font-size: 24px;
    }
    &__left--mobile {
      flex-direction: column;
      align-items: flex-start;
      &__logo {
        margin: 20px;
      }
      .portal-header__left__menu {
        flex-direction: column;
        margin: 0 0 10px 0;
        margin-top: 20px;
      }
      .portal-header__left__logo {
        height: 40px;
        margin-bottom: 20px;
      }
    }
    &__container {
      padding: 0 10px;
    }
    &__action {
      &__log-in,
      &__language {
        font-size: 12px;
      }
      &__start__button {
        height: 34px;
        padding: 0 6px;
        margin: 0 10px;
        font-size: 12px;
      }
    }
  }
}
</style>
<style lang="less">
@media screen and (max-width: 1260px) {
  .portal-header__drawer {
    .ant-drawer-body {
      padding: 20px 0;
    }
  }
}
</style>
