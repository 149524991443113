import { request, METHOD, isDevelopment } from '@/utils'
// 上传图片
export const uploadSingleImage = params => {
  return request('/saas-api/bff/v1/file/upload-image', METHOD.POST, params, {
    headers: { source: 'OSHOP' },
    timeout: 120000,
  })
}
// 上传图片
export const uploadSingleImageNoAuthentication = params => {
  // return request('/saas-api/bms/bff/v1/file/upload-image', METHOD.POST, params, {
  //   timeout: 120000,
  // })
  return request('/saas-bms-api/bff/v1/file/upload-image', METHOD.POST, params, {
    timeout: 120000,
  })
}

// 删除图片
export const deleteImage = params => {
  return request('/saas-api/bff/v1/file/delete', METHOD.POST, params)
}
// 获取国家
export const getCountryList = params => {
  return request('/saas-api/bff/unAuth/v1/country', METHOD.GET, params)
}
// 获取行业
export const getBusinessList = params => {
  return request('/saas-api/bff/unAuth/v1/dict/INDUSTRY/item', METHOD.GET, params)
}
// 根据国家获取区域 （州）
export const getCountryArea = params => {
  return request(`/saas-api/bff/unAuth/v1/${params.countryId}/area`, METHOD.GET)
}
// 根据国家获取区域 （州）/市
export const getStateCity = params => {
  return request(`/saas-api/bff/unAuth/v2/${params.countryId}/area`, METHOD.GET)
}
// 切换当前登录人后台系统语言
export const changeLanguage = params => {
  return request('/saas-api/account/v1/shop-user/change-user-language', METHOD.POST, params)
}
// 将原始图片url上传到oss
export const getOssUrlByImageUrl = params => {
  return request('/saas-api/bff/v1/file/upload-image-origin-url', METHOD.POST, params)
}
// 根据ip获取国家
export const getCountryCode = params => {
  return request('/saas-api/bff/ip/v1/getCountryCode', METHOD.GET, params, {
    needPreprocessBusinessError: false,
  })
}
// 获取系统配置信息
export const getWebConfig = params => {
  return request('/saas-api/bff/unAuth/v1/web-config', METHOD.POST, params, {
    needPreprocessBusinessError: false,
  })
}

// 获取所有广告位
export const getCustomerBanner = params => {
  return isDevelopment()
    ? request('/mock-api/marketing/propagate/api/v1/get-customer-banner-pc', METHOD.POST, params)
    : request('/saas-api/marketing/propagate/api/v1/get-customer-banner-pc', METHOD.POST, params)
}
