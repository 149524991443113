import { JSEncrypt } from 'jsencrypt'

/**
 * RSA加密解密工具类
 * 官方网站：http://travistidwell.com/jsencrypt/
 * 使用方式：
 * import rsaUtil from '@/utils/rsa-util'
 * const encryptData = rsaUtil.encrypt(data,key)
 * const decrypt = rsaUtil.decrypt(data,key)
 */
const rsaUtil = {
  /**
   * 加密函数
   * @param {String} data 待加密数据
   * @param {String}  publicKey 公钥Key
   * @returns {String} 加密后的数据
   */
  encrypt: (data, publicKey) => {
    const encrypt = new JSEncrypt()
    encrypt.setPublicKey(publicKey)
    return encrypt.encrypt(data)
  },
  /**
   * 加密函数
   * @param {String} data 待解密数据
   * @param {String}  privateKey 私钥Key
   * @returns {String} 解密后的数据
   */
  decrypt: (data, privateKey) => {
    const decrypt = new JSEncrypt()
    decrypt.setPrivateKey(privateKey)
    return decrypt.decrypt(data)
  },
}

export default rsaUtil
