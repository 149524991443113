import store from '@/store'
import router from '@/router'
import {
  localStorage,
  isPandovaApp,
  JSBridge,
  PortalAnalytics,
  MerchantAnalytics,
  isMobile,
  setLanguageAndCountry,
  setBannerData,
} from '@/utils'
import { BANNER_CONFIG_TYPE, NATION_TYPE } from '@/constants/common'

const loginRoutePath = '/account/login'
const shopListRoutePath = '/account/shop-list'
const shopAddRoutePath = '/account/shop-add'

// 加载路由守卫
const loadRouterGuard = () => {
  router.beforeEach((to, from, next) => {
    const noAccessToken = !localStorage.get('accessToken')
    const noShopInfo = !localStorage.get('shopInfo', {}).shopId
    const needLogin = !to.meta.notNeedLogin
    // 需要登录 && Pandova套壳内：获取登录信息后跳转(每次获取LoginInfo目的在于动态切换语言)
    if (needLogin && isPandovaApp()) {
      JSBridge.getLoginInfo().then(res => {
        const { userInfo, shopInfo, token } = res
        store.commit('setUserInfo', userInfo || {})
        store.commit('setLanguage', (userInfo || {}).languageCode)
        store.commit('setShopInfo', shopInfo || {})
        store.commit('setAccessToken', token)
        next()
      })
    }
    // 需要登录 && 无Token && WEB内：跳转到登录页面
    if (needLogin && noAccessToken && !isPandovaApp()) {
      next({ path: loginRoutePath })
    }
    // 需要登录 && 有Token && 无店铺信息 && 没有在店铺列表和店铺添加页面：进入店铺列表页面
    if (
      needLogin &&
      !noAccessToken &&
      noShopInfo &&
      ![shopListRoutePath, shopAddRoutePath].includes(to.path)
    ) {
      next({ path: shopListRoutePath })
    }
    next()
  })
  router.afterEach(to => {
    ;(document.querySelector('.portal-layout') || {}).scrollTop =
      document.body.scrollTop =
      document.documentElement.scrollTop =
        0
    const { pv, hideAppTitleBar, isPromotionPage, notNeedLogin, title } = to.meta || {}
    if (isPandovaApp() && !notNeedLogin) {
      JSBridge.setTitle({ title })
    }
    if (!isPandovaApp() && !notNeedLogin) {
      document.title = title
    }
    if (!isPandovaApp() && notNeedLogin) {
      document.title = 'Boost Your Online Business with Pandova'
    }
    // PV上报
    if (pv) {
      pv.includes('spportal_') && PortalAnalytics.pv(pv, to.query)
      pv.includes('spmerchant_') && MerchantAnalytics.pv(pv, to.query)
    }
    // APP端ToolBar默认隐藏（主菜单体验优化，避免加载后抖动显示），其他页面动态展示
    if (isPandovaApp() && !hideAppTitleBar) {
      JSBridge.showTitleBar()
    }
    // 活动渠道码埋点
    if (isPromotionPage) {
      const { firstLevelCode, secondLevelCode, thirdLevelCode } = to.query
      if (firstLevelCode && secondLevelCode && thirdLevelCode) {
        localStorage.set('promotionChannelInfo', {
          firstLevelCode,
          secondLevelCode,
          thirdLevelCode,
          createTime: Date.now(),
        })
      }
    }
  })
}

// 为body添加类选择器（Pandova APP场景）
const addBodyClassSelectorForPandovaApp = () => {
  if (isMobile()) {
    document.getElementsByTagName('body')[0].setAttribute('class', 'app-pandova')
  }
}

// 全局启动方法
const bootstrap = () => {
  addBodyClassSelectorForPandovaApp()
  store.commit('setCountryCode', localStorage.get('countryCode', NATION_TYPE.NIGERIA))
  store.commit('setUserInfo', localStorage.get('userInfo', {}))
  store.commit('setShopInfo', localStorage.get('shopInfo', {}))
  store.commit('setShopList', localStorage.get('shopList', {}))
  store.commit('setAccessToken', localStorage.get('accessToken', ''))
  store.commit(
    'setShopPreviewPopoverVisible',
    localStorage.get('shopPreviewPopoverVisible', !isPandovaApp())
  )
  loadRouterGuard()
  setLanguageAndCountry()
  setBannerData(BANNER_CONFIG_TYPE.ADMIN, 'setAdminBannerList')
  setBannerData(BANNER_CONFIG_TYPE.WEBSITE, 'setPortalBannerList')
}
export default bootstrap
