<template>
  <div class="account-layout">
    <a-row
      type="flex"
      justify="center"
      class="account-layout__row"
    >
      <a-col
        :xs="0"
        :md="0"
        :lg="14"
        class="account-layout__left-content"
      >
        <a-row
          type="flex"
          justify="space-between"
        >
          <img
            v-if="isNeedLoadImg"
            v-compress="{
              src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/account/logo-light.png',
            }"
            class="account-layout__left-content__logo"
          />
          <img
            v-if="isNeedLoadImg"
            v-compress="{
              src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/account/logo-half.png',
            }"
            class="account-layout__left-content__half-logo"
          />
        </a-row>
        <a-row
          type="flex"
          justify="center"
          align="middle"
          class="account-layout__left-content__theme-row"
        >
          <img
            v-if="isNeedLoadImg"
            v-compress="{
              src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/account/theme-bg.png',
            }"
            class="account-layout__left-content__theme-img"
          />
        </a-row>
      </a-col>
      <a-col
        :xs="24"
        :md="24"
        :lg="10"
        class="account-layout__right-content"
      >
        <router-view />
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'AccountLayout',
  computed: {
    isNeedLoadImg() {
      return document.body.clientWidth > 992
    },
  },
}
</script>

<style lang="less" scoped>
.account-layout {
  /deep/ .ant-form-item-label {
    line-height: 28px;
  }
  &__row {
    min-height: 100vh;
  }
  &__left-content {
    background-color: @primary-color;
    &__logo {
      width: 200px;
      height: 48px;
      margin: 50px;
    }
    &__half-logo {
      width: 270px;
      height: 160px;
    }
    &__theme-row {
      height: calc(100vh - 200px);
    }
    &__theme-img {
      width: 570px;
      height: 420px;
    }
  }
  &__right-content {
    min-height: 100vh;
    background-color: #fff;
  }
}
</style>
