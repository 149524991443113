/**
 * 获取OSS图片压缩URL
 * @param {Object} src:图片地址，width：图片宽度，height：图片高度，quality：图片质量
 * @returns 压缩后的URL
 * @example
 * https://help.aliyun.com/document_detail/44688.html  阿里云压缩吗参数参考
 * getOssImageCompressUrl({
 *  height: 0,
 *   quality: 80,
 *   src: "https://saas-shop-prod.pandova.com/theme/40000004/8bec8a4d-b2f0-435b-a4aa-709e3c6b3958.png",
 *   width: 1464
 * })
 * =>'https://saas-shop-prod.pandova.com/theme/40000004/c1c2a7ad-7dc0-4cf3-b594-bbfa9882548c.png?x-oss-process=image/auto-orient,1/quality,Q_80/bright,-1/format,webp'
 */

export const getOssImageCompressUrl = (paramConfig = {}) => {
  const defaultConfig = { src: '', width: 0, height: 0, quality: 80 }
  const config = { ...defaultConfig, ...paramConfig }
  const imgUrl = config.src || ''
  // 如果不是oss 的图片直接返回
  if (
    (!imgUrl.includes('aliyuncs.com') &&
      !imgUrl.includes('saas-fe-prod.pandova.com') &&
      !imgUrl.includes('saas-fe-prod.pandova.cloud') &&
      !imgUrl.includes('saas-shop-prod.pandova.com') &&
      !imgUrl.includes('saas-shop-prod.pandova.cloud')) ||
    imgUrl.indexOf('data:image') === 0 ||
    imgUrl.includes('x-oss-process=')
  ) {
    return imgUrl
  }

  // 获取显示设备 pixelRatio
  const pixelRatio = window.devicePixelRatio
  const elWidth = Math.ceil(config.width * pixelRatio)
  const elHeight = Math.ceil(config.height * pixelRatio)
  let resize = '/resize'
  if (elWidth) {
    resize += `,w_${elWidth}`
  }
  if (elHeight) {
    resize += `,h_${elHeight}`
  }

  if (!elWidth && !elHeight) {
    resize = ''
  }

  // 设置图片压缩质量
  let quality = config.quality
  if (!quality) {
    quality = ''
  }

  // 判断webp
  const canUseWebp =
    document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
  let webp = ''
  if (canUseWebp) {
    webp = '/format,webp'
  }

  // 阿里云OSS图片压缩方案url
  return `${imgUrl}?x-oss-process=image${resize}/auto-orient,1/quality,Q_${quality}/bright,-1${webp}`
}
