/**
 * 是否为Pandova App环境
 * @returns {Boolean}
 */
export const isPandovaApp = () => navigator.userAgent.includes('Pandova')

/**
 * 是否为Pandova IOS环境
 * @returns {Boolean}
 */
export const isPandovaIOS = () => navigator.userAgent.includes('Pandova-iOS')

/**
 * 是否为Pandova Android环境
 * @returns {Boolean}
 */
export const isPandovaAndroid = () => navigator.userAgent.includes('Pandova-Android')

/**
 * 是否为 IOS环境
 * @returns {Boolean}
 */
export const isIOS = () => !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

/**
 * 是否为 Android环境
 * @returns {Boolean}
 */
export const isAndroid = () =>
  navigator.userAgent.includes('Android') || navigator.userAgent.includes('Adr')

/**
 * 判断是否为移动端
 * @returns
 */
export const isMobile = () => {
  return navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|Pandova)/i
  )
}

/**
 * 是否为开发环境
 * @returns
 */
export const isDevelopment = () => {
  return process.env.VUE_APP_ENV === 'development'
}

/**
 * 是否为测试环境
 * @returns
 */
export const isTest = () => {
  return process.env.VUE_APP_ENV === 'test'
}

/**
 * 是否为生产环境
 * @returns
 */
export const isProduction = () => {
  return process.env.VUE_APP_ENV === 'production'
}

/**
 * 获取埋点平台类型:pc、h5、app-ios、app-android
 */
export const getOPayAnalyticsPlatform = () => {
  if (isPandovaIOS()) return 'app-ios'
  if (isPandovaAndroid()) return 'app-android'
  if (isMobile()) return 'h5'
  return 'pc'
}
