import IconExpand from '@/assets/icons/icon-expand.svg'

export const getThemeSchema = () => ({
  global: [
    {
      label: $t('onlineShop.themeDesign.globalColor'),
      icon: 'bg-colors',
      id: 'colorInfo',
      type: 'colorInfo',
      settings: [
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-title',
          props: {
            label: $t('onlineShop.common.titleColor'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-sub-title',
          props: {
            label: $t('onlineShop.common.subTitleColor'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-text',
          props: {
            label: $t('onlineShop.themeDesign.globalContentText'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-product-origin-price',
          props: {
            label: $t('product.productEdit.originalPrice'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-product-sale-price',
          props: {
            label: $t('product.productEdit.salePrice'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-primary-button-background',
          props: {
            label: $t('onlineShop.themeDesign.globalPrimaryButtonBgColor'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-primary-button-text',
          props: {
            label: $t('onlineShop.themeDesign.globalPrimaryButtonTextColor'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-primary-button-outline',
          props: {
            label: $t('onlineShop.themeDesign.globalPrimaryButtonBdColor'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-secondary-button-background',
          props: {
            label: $t('onlineShop.themeDesign.globalSubButtonBgColor'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-secondary-button-text',
          props: {
            label: $t('onlineShop.themeDesign.globalSubButtonTextColor'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          value: 'rgba(0, 0, 0, 1)',
          key: 'color-secondary-button-outline',
          props: {
            label: $t('onlineShop.themeDesign.globalSubButtonBdColor'),
          },
        },
      ],
    },
    {
      label: $t('onlineShop.themeDesign.globalFontFamily'),
      icon: 'font-colors',
      type: 'fontInfo',
      id: 'fontInfo',
      settings: [
        {
          type: 'AttributeComponentSelect',
          value: '14px',
          key: 'font-size-text',
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
            label: $t('onlineShop.themeDesign.globalContentTextFontSize'),
            options: [
              { label: $t('onlineShop.themeDesign.componentAttrsAlignSmall'), value: '14px' },
              { label: $t('onlineShop.themeDesign.componentAttrsAlignMiddle'), value: '16px' },
              { label: $t('onlineShop.themeDesign.componentAttrsSelectLarge'), value: '18px' },
            ],
          },
        },
        {
          type: 'AttributeComponentSelect',
          value: 'normal',
          key: 'font-weight-text',
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
            label: $t('onlineShop.themeDesign.globalContentTextFontWeight'),
            options: [
              { value: 'normal', label: $t('onlineShop.themeDesign.componentAttrsNormal') },
              { value: 'bold', label: $t('onlineShop.themeDesign.componentAttrsBold') },
              { value: 'bolder', label: $t('onlineShop.themeDesign.componentAttrsBolder') },
              { value: 'lighter', label: $t('onlineShop.themeDesign.componentAttrsLighter') },
            ],
          },
        },
      ],
    },
    {
      label: $t('onlineShop.themeDesign.globalSocialMedia'),
      icon: 'contacts',
      type: 'socialApp',
      id: 'socialApp',
      explain: $t('onlineShop.themeDesign.globalGuidanceText'),
      settings: [
        {
          type: 'AttributeComponentInput',
          value: '',
          key: 'facebook',
          props: {
            label: $t('onlineShop.themeDesign.globalFacebookLink'),
            explain: $t('onlineShop.themeDesign.globalFacebookPlaceholder'),
          },
        },
        {
          type: 'AttributeComponentInput',
          value: '',
          key: 'instagram',
          props: {
            label: $t('onlineShop.themeDesign.globalInstagramText'),
            explain: $t('onlineShop.themeDesign.globalInstagramPlaceholder'),
          },
        },
        {
          type: 'AttributeComponentInput',
          value: '',
          key: 'twitter',
          props: {
            label: $t('onlineShop.themeDesign.globalTwitterLink'),
            explain: $t('onlineShop.themeDesign.globalTwitterPlaceholder'),
          },
        },
        {
          type: 'AttributeComponentInput',
          value: '',
          key: 'pinterest',
          props: {
            label: $t('onlineShop.themeDesign.globalPinterestText'),
            explain: $t('onlineShop.themeDesign.globalPinterestPlaceholder'),
          },
        },
        {
          type: 'AttributeComponentInput',
          value: '',
          key: 'youTube',
          props: {
            label: $t('onlineShop.themeDesign.globalYouTubeText'),
            explain: $t('onlineShop.themeDesign.globalYouTubePlaceholder'),
          },
        },
      ],
    },
  ],
  sectionsCategoryList: [
    { icon: 'appstore', label: $t('onlineShop.themeDesign.categoryTitleProduct'), value: 'goods' },
    // { icon: 'hdd', label: $t('onlineShop.themeDesign.categoryTextLink'), value: 'textLink' },
    { icon: 'hdd', label: $t('onlineShop.themeDesign.categoryImageLink'), value: 'imageLink' },
  ],
  sections: [
    {
      type: 'image-banner',
      id: 'eb4110b98-11e22-4c50-96026-2d82e30aec137',
      label: $t('onlineShop.themeDesign.componentBanner'),
      blockAddType: 'same',
      imageUrl:
        'https://saas-fe-prod.pandova.com/static/pandova-admin/images/online-design/design-component-image-banner.png',
      categoryValue: 'imageLink',
      isDisable: true,
      isDraggable: true,
      isRemove: true,
      icon: 'sound',
      blockOrder: [],
      blocks: [],
      settings: [
        {
          type: 'AttributeComponentImageUpload',
          key: 'imageUrl',
          value: '',
          props: {
            label: $t('onlineShop.themeDesign.categoryImageLink'),
          },
        },
        {
          type: 'AttributeComponentLinkSelect',
          key: 'linkUrl',
          value: {},
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrLink'),
          },
        },
        {
          type: 'AttributeComponentInput',
          key: 'heading',
          value: $t('onlineShop.themeDesign.componentAttrImageBannerDefaultTitle'),
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrTitle'),
          },
        },
        {
          type: 'AttributeComponentInput',
          key: 'text',
          value: $t('onlineShop.themeDesign.componentAttrLabelDefaultTitle'),
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrTextPlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrTextPlaceholder'),
          },
        },
        {
          type: 'AttributeComponentSelect',
          key: 'containerWidth',
          value: 'full',
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrsContainerWidth'),
            options: [
              { value: 'full', label: $t('onlineShop.themeDesign.componentAttrsFullLabel') },
              { value: 'padding', label: $t('onlineShop.themeDesign.componentAttrsPaddingLabel') },
            ],
          },
        },
        {
          type: 'AttributeComponentSelect',
          key: 'textAlign',
          value: 'center',
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrsAlignMode'),
            options: [
              { value: 'left', label: $t('onlineShop.themeDesign.componentAttrsAlignLeft') },
              { value: 'center', label: $t('onlineShop.themeDesign.componentAttrsAlignCenter') },
              { value: 'right', label: $t('onlineShop.themeDesign.componentAttrsAlignRIght') },
            ],
          },
        },
      ],
    },
    {
      id: 'eb410b98-11e2-4c50-9606-2d82e0aec137',
      label: $t('onlineShop.themeDesign.componentAnnouncement'),
      type: 'announcement-bar',
      blockAddType: 'same',
      imageUrl:
        'https://saas-fe-prod.pandova.com/static/pandova-admin/images/online-design/design-component-announcement-bar.png',
      categoryValue: null,
      isDisable: true,
      isDraggable: false,
      isRemove: false,
      icon: 'sound',
      settings: [],
      blocks: [
        {
          id: 'd5eccfa1-4963-47e7-9f10-9714260c5f84',
          label: 'item',
          type: 'announcement',
          icon: 'sound',
          isDisable: true,
          isDraggable: true,
          isRemove: true,
          settings: [
            {
              id: '880a8b48-fc35-481e-90bf-c9320ce31037',
              type: 'AttributeComponentInput',
              key: 'announcementText',
              value: $t('onlineShop.themeDesign.componentAttrBarDefault'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTextPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrText'),
              },
            },
            {
              settingId: '920595ae-79f1-4939-b051-c391b7a1e233',
              type: 'AttributeComponentColorPicker',
              key: 'colorBg',
              value: 'rgba(255,255,255,1)',
              props: {
                label: $t('onlineShop.themeDesign.componentAttrBgc'),
              },
            },
            {
              settingId: 'bf3fb12f-4e33-4acd-a570-686ee99596d0',
              type: 'AttributeComponentColorPicker',
              key: 'colorText',
              value: 'rgba(0,0,0,1)',
              props: {
                label: $t('onlineShop.themeDesign.globalTextColor'),
              },
            },
            {
              settingId: 'edf5d559-b18e-4392-b538-ff330f33e0f5',
              type: 'AttributeComponentLinkSelect',
              key: 'announcementLink',
              value: {},
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrLink'),
              },
            },
          ],
          disabled: false,
        },
      ],
    },
    {
      sectionId: 'be34ac6c-3201-4e8e-9af3-ac534773bb9f',
      type: 'layout-header',
      label: $t('onlineShop.themeDesign.componentHeader'),
      blockAddType: 'same',
      imageUrl: 'https://b.yzcdn.cn/i18n-b/df557f2f84beaebb2a5362342075a9c6.png',
      icon: 'sound',
      categoryValue: null,
      isDisable: false,
      isDraggable: false,
      isRemove: false,
      settings: [
        {
          settingId: '3963706f-0076-44b8-87b4-999f93e2fdb7',
          type: 'AttributeComponentColorPicker',
          value: '#rgba(255, 255, 255, 1)',
          key: 'colorBg',
          props: {
            label: $t('onlineShop.themeDesign.componentAttrBgc'),
          },
        },
        {
          settingId: 'colorText',
          type: 'AttributeComponentColorPicker',
          key: 'colorText',
          value: 'rgba(0, 0, 0, 0.8)',
          props: {
            label: $t('onlineShop.themeDesign.globalTextColor'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          settingId: '4ad5418f-3158-4f67-bc85-eb4ee0077869',
          key: 'subMenuColorText',
          value: 'rgba(0, 0, 0, 0.8)',
          props: {
            label: $t('onlineShop.themeDesign.componentAttrSecondaryMenuColor'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          settingId: 'c6ab1c5c-1d6a-4c0e-b702-5f946c808e7a',
          key: 'subMenuColorBg',
          value: '#rgba(255, 255, 255, 1)',
          props: {
            label: $t('onlineShop.themeDesign.componentAttrSecondaryMenuBgcColor'),
          },
        },
        {
          type: 'AttributeComponentImageUpload',
          settingId: '104ab982-2461-4dcf-bebf-6aeaf49018ca',
          key: 'logoUrl',
          value: '',
          props: {
            label: $t('onlineShop.themeDesign.componentAttrLogo'),
          },
        },

        {
          type: 'AttributeComponentCheckbox',
          settingId: '955b343c-5461-416a-a769-c1761f05bc5e',
          key: 'showLineSeparator',
          value: false,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrShowButtomLine'),
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: 'marginBottom',
          key: 'marginBottom',
          value: 16,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrButtonMargin'),
            min: 4,
            max: 32,
            step: 4,
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          key: 'mobileMenuColorBg',
          settingId: 'mobileMenuColorBg',
          value: '#000',
          props: {
            label: $t('onlineShop.themeDesign.componentAttrMobileMenuBgc'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          key: 'mobileMenuColorText',
          settingId: 'mobileMenuColorText',
          value: '#fff',
          props: {
            label: $t('onlineShop.themeDesign.componentAttrMobileMenuTextColor'),
          },
        },
        {
          type: 'AttributeComponentHeaderMenuSelect',
          key: 'headerItems',
          value: {
            id: null,
            title: '',
            items: [],
          },
          props: {
            placeholder: $t('onlineShop.themeDesign.menuSelectionPlaceholder'),
            label: $t('onlineShop.themeDesign.menuTitle'),
          },
        },
      ],
      blocks: [],
    },
    {
      sectionId: '7575bb7d-c170-408f-bdfa-67b963c5bc98',
      type: 'featured-collection',
      label: $t('onlineShop.themeDesign.componentProductCollection'),
      blockAddType: 'same',
      isDisable: true,
      isDraggable: true,
      isRemove: true,
      imageUrl:
        'https://saas-fe-prod.pandova.com/static/pandova-admin/images/online-design/design-component-featured-collection.png',
      categoryValue: 'goods',
      settings: [
        {
          type: 'AttributeComponentInput',
          settingId: 'title',
          key: 'title',
          value: $t('onlineShop.themeDesign.componentAttrGoodsSetDefault'),
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrTitle'),
          },
        },
        {
          type: 'AttributeComponentCollectionSelect',
          settingId: 'collection',
          key: 'collection',
          value: -1,
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
            label: $t('onlineShop.themeDesign.componentProductCollection'),
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: 'paddingTop',
          key: 'paddingTop',
          value: 16,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrMarginTop'),
            min: 4,
            max: 32,
            step: 4,
          },
        },
        {
          label: '底部边距',
          type: 'AttributeComponentSlider',
          settingId: 'paddingBottom',
          key: 'paddingBottom',
          value: 16,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrButtonMargin'),
            min: 4,
            max: 32,
            step: 4,
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: 'imageRatio',
          key: 'imageRatio',
          value: 4,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrImgRadius'),
            min: 0,
            max: 8,
            step: 2,
          },
        },
      ],
      blocks: [],
    },
    {
      sectionId: '1521bb7d-c170-448f-bdf5-67b963c5bc98',
      type: 'latest-product',
      label: $t('onlineShop.themeDesign.newProduct'),
      blockAddType: 'same',
      isDisable: true,
      isDraggable: true,
      isRemove: true,
      imageUrl:
        'https://saas-fe-prod.pandova.com/static/pandova-admin/images/online-design/design-component-latested-product.png',
      categoryValue: 'goods',
      settings: [
        {
          type: 'AttributeComponentInput',
          settingId: 'title',
          key: 'title',
          value: $t('onlineShop.themeDesign.newProduct'),
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrTitle'),
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: 'pageSize',
          key: 'pageSize',
          value: 8,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrsMaxNum'),
            min: 4,
            max: 16,
            step: 4,
          },
        },
      ],
      blocks: [],
    },
    {
      sectionId: 'cd88af31-c439-49f6-9174-9f7e695f0f02',
      type: 'featured-product',
      imageUrl:
        'https://saas-fe-prod.pandova.com/static/pandova-admin/images/online-design/design-component-featured-product.png',
      label: $t('onlineShop.themeDesign.componentRecommendProduct'),
      isDisable: true,
      isDraggable: true,
      isRemove: true,
      blockAddType: 'same',
      icon: 'sound',
      categoryValue: 'goods',
      settings: [
        {
          type: 'AttributeComponentInput',
          settingId: '88b4410f-6866-4fb9-a866-cf12922b4d01',
          key: 'title',
          value: $t('onlineShop.themeDesign.componentAttrBESTTitle'),
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrTitle'),
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: 'imageRatio',
          key: 'imageRatio',
          value: 4,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrRadius'),
            min: 0,
            max: 8,
            step: 1,
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: 'paddingTop',
          key: 'paddingTop',
          value: 16,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrTopPadding'),
            min: 4,
            max: 32,
            step: 4,
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: 'paddingBottom',
          key: 'paddingBottom',
          value: 16,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrButtonPadding'),
            min: 4,
            max: 32,
            step: 4,
          },
        },
      ],
      blocks: [
        {
          blockId: 'product-01',
          label: 'item',
          type: 'product',
          isDisable: true,
          isDraggable: true,
          isRemove: true,
          icon: 'shopping',
          settings: [
            {
              type: 'AttributeComponentProductSelect',
              settingId: 'productId',
              key: 'productId',
              value: -1,
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrProductLink'),
              },
            },
          ],
          disabled: false,
        },
        {
          blockId: 'product-02',
          label: 'item',
          type: 'product',
          isDisable: true,
          isDraggable: true,
          isRemove: true,
          icon: 'shopping',
          settings: [
            {
              type: 'AttributeComponentProductSelect',
              settingId: 'productId',
              key: 'productId',
              value: -2,
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrProductLink'),
              },
            },
          ],
          disabled: false,
        },
        {
          blockId: 'product-03',
          label: 'item',
          type: 'product',
          isDisable: true,
          isDraggable: true,
          isRemove: true,
          icon: 'shopping',
          settings: [
            {
              type: 'AttributeComponentProductSelect',
              settingId: 'productId',
              key: 'productId',
              value: -3,
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrProductLink'),
              },
            },
          ],
          disabled: false,
        },
        {
          blockId: 'product-04',
          label: 'item',
          type: 'product',
          isDisable: true,
          isDraggable: true,
          isRemove: true,
          icon: 'shopping',
          settings: [
            {
              type: 'AttributeComponentProductSelect',
              settingId: 'productId',
              key: 'productId',
              value: -4,
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrProductLink'),
              },
            },
          ],
          disabled: false,
        },
        {
          blockId: 'product-05',
          label: 'item',
          type: 'product',
          isDisable: true,
          isDraggable: true,
          isRemove: true,
          icon: 'shopping',
          settings: [
            {
              type: 'AttributeComponentProductSelect',
              settingId: 'productId',
              key: 'productId',
              value: -5,
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrProductLink'),
              },
            },
          ],
          disabled: false,
        },
        {
          blockId: 'product-06',
          label: 'item',
          type: 'product',
          isDisable: true,
          isDraggable: true,
          isRemove: true,
          icon: 'shopping',
          settings: [
            {
              type: 'AttributeComponentProductSelect',
              settingId: 'productId',
              key: 'productId',
              value: -6,
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrProductLink'),
              },
            },
          ],
          disabled: false,
        },
      ],
    },
    {
      sectionId: '49bd738d-dbf4-4a6f-b434-e36087bd3dc7',
      type: 'slide-show',
      label: $t('onlineShop.themeDesign.componentSlideshow'),
      imageUrl:
        'https://saas-fe-prod.pandova.com/static/pandova-admin/images/online-design/design-component-slide-show.png',
      icon: 'sound',
      blockAddType: 'same',
      categoryValue: 'imageLink',
      isDisable: true,
      isDraggable: true,
      isRemove: true,
      settings: [
        {
          type: 'AttributeComponentSelect',
          settingId: '2611e894-9a9b-4274-91b6-7a0c6ebf4723',
          key: 'slideShowHeight',
          value: 'middle',
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrSlideHeight'),
            options: [
              {
                label: $t('onlineShop.themeDesign.componentAttrsSelectLarge'),
                value: 'large',
              },
              {
                label: $t('onlineShop.themeDesign.componentAttrsAlignMiddle'),
                value: 'middle',
              },
              {
                label: $t('onlineShop.themeDesign.componentAttrsAlignSmall'),
                value: 'small',
              },
            ],
          },
        },
        {
          type: 'AttributeComponentSelect',
          settingId: '16d622b4-2925-4b84-b3ba-ff70aeed6724',
          key: 'slideShowWidth',
          value: 'full',
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrSlideWidth'),
            options: [
              {
                label: $t('onlineShop.themeDesign.componentAttrsFullLabel'),
                value: 'full',
              },
              {
                label: $t('onlineShop.themeDesign.componentAttrsPaddingLabel'),
                value: 'padding',
              },
            ],
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: 'changeSlidesSpeed',
          key: 'changeSlidesSpeed',
          value: 3000,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrSlideSpeed'),
            min: 1000,
            max: 10000,
            step: 1000,
          },
        },
      ],
      blocks: [
        {
          blockId: 'slide-01',
          type: 'slide',
          label: 'item',
          isDisable: true,
          isDraggable: true,
          isRemove: true,
          icon: 'picture',
          settings: [
            {
              type: 'AttributeComponentColorPicker',
              settingId: 'sdf2129c5-11f7-42209-230c-es533aad23413',
              key: 'headingColor',
              value: 'rgba(0, 0, 0, 1)',
              props: {
                label: $t('onlineShop.common.titleColor'),
              },
            },
            {
              type: 'AttributeComponentImageUpload',
              settingId: 'sdf129c5-11f7-4209-230c-es53aad23413',
              key: 'imageUrl',
              value: '',
              props: {
                label: $t('onlineShop.themeDesign.componentAttrSliderImage'),
              },
            },
            {
              type: 'AttributeComponentCheckbox',
              settingId: 'ed6129c5-11f7-4209-860c-ee53aad44864',
              key: 'isShowText',
              value: true,
              props: {
                label: $t('onlineShop.themeDesign.componentAttrsliderTextShow'),
              },
            },
            {
              type: 'AttributeComponentColorPicker',
              settingId: '69d2abf6-b818-41ee-b290-35649e1c22f0',
              key: 'textBoxBgColor',
              value: '#fff',
              props: {
                label: $t('onlineShop.themeDesign.globalTextBgcColor'),
              },
            },
            {
              type: 'AttributeComponentInput',
              settingId: 'a3da2a58-148b-420c-9326-b0515c63f037',
              key: 'heading',
              value: $t('onlineShop.themeDesign.componentAttrSlideDefaultTitle'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrTitle'),
              },
            },
            {
              type: 'AttributeComponentSlider',
              settingId: 'eb530d38-550d-4a71-9a7d-81f7396581cc',
              key: 'headingSize',
              value: 14,
              props: {
                label: $t('onlineShop.themeDesign.globalFontSize'),
                min: 12,
                max: 38,
                step: 2,
              },
            },
            {
              type: 'AttributeComponentTextarea',
              settingId: '00c1ec1c-5720-41db-bac5-d4c161fd59b2',
              key: 'subheading',
              value: $t('onlineShop.themeDesign.componentAttrSlideDefaultSubTitle'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTextPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrTwoTitle'),
              },
            },
            {
              type: 'AttributeComponentInput',
              settingId: 'd24f72b9-c5da-4728-aa87-9bc5fe98acd5',
              key: 'buttonLabel',
              value: $t('onlineShop.themeDesign.componentAttrButtonDefaultSubTitle'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTextPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrButtonText'),
              },
            },
            {
              type: 'AttributeComponentLinkSelect',
              settingId: 'cc90a7dd-fce5-4e7d-a81c-18eeeb78dc07',
              key: 'link',
              value: {},
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrButtonLink'),
              },
            },
            {
              type: 'AttributeComponentSelect',
              settingId: '9321351b-f253-4ab3-a9dc-6f0b4798c79f',
              key: 'outlineButtonStyle',
              value: 'primary',
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrButtonStyle'),
                options: [
                  {
                    label: $t('onlineShop.themeDesign.componentAttrPrimaryButton'),
                    value: 'primary',
                  },
                  {
                    label: $t('onlineShop.themeDesign.componentAttrMinorButton'),
                    value: 'outline',
                  },
                ],
              },
            },
            {
              type: 'AttributeComponentSlider',
              settingId: 'bd3f51c3-22ce-4072-a3fe-0cec811947ac',
              key: 'imageOverlayOpacity',
              value: 1,
              props: {
                label: $t('onlineShop.themeDesign.componentAttrImgTransparency'),
                min: 0,
                max: 1,
                step: 0.1,
              },
            },
          ],
          disabled: false,
        },
        {
          blockId: 'slide-02',
          type: 'slide',
          label: 'item',
          isDisable: true,
          isDraggable: true,
          isRemove: true,
          icon: 'picture',
          settings: [
            {
              type: 'AttributeComponentColorPicker',
              settingId: 'sdf2129c5-11f7-42209-230c-es533aad23413',
              key: 'headingColor',
              value: 'rgba(0, 0, 0, 1)',
              props: {
                label: $t('onlineShop.common.titleColor'),
              },
            },
            {
              type: 'AttributeComponentImageUpload',
              settingId: 'sdf129c5-11f7-4209-230c-es53aad234131',
              key: 'imageUrl',
              value: '',
              props: {
                label: $t('onlineShop.themeDesign.componentAttrSliderImage'),
              },
            },
            {
              type: 'AttributeComponentCheckbox',
              settingId: 'ed6129c5-11f7-4209-860c-ee53aad44864',
              key: 'isShowText',
              value: true,
              props: {
                label: $t('onlineShop.themeDesign.componentAttrsliderTextShow'),
              },
            },
            {
              type: 'AttributeComponentColorPicker',
              settingId: '69d2abf6-b818-41ee-b290-35649e1c22f0',
              key: 'textBoxBgColor',
              value: '#fff',
              props: {
                label: $t('onlineShop.themeDesign.globalTextBgcColor'),
              },
            },
            {
              type: 'AttributeComponentInput',
              settingId: 'a3da2a58-148b-420c-9326-b0515c63f037',
              key: 'heading',
              value: $t('onlineShop.themeDesign.componentAttrSlideDefaultTitle'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrTitle'),
              },
            },
            {
              type: 'AttributeComponentSlider',
              settingId: 'eb530d38-550d-4a71-9a7d-81f7396581cc',
              key: 'headingSize',
              value: 14,
              props: {
                label: $t('onlineShop.themeDesign.globalFontSize'),
                min: 12,
                max: 38,
                step: 2,
              },
            },
            {
              type: 'AttributeComponentTextarea',
              settingId: '00c1ec1c-5720-41db-bac5-d4c161fd59b2',
              key: 'subheading',
              value: $t('onlineShop.themeDesign.componentAttrSlideDefaultSubTitle'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTextPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrTwoTitle'),
              },
            },
            {
              type: 'AttributeComponentInput',
              settingId: 'd24f72b9-c5da-4728-aa87-9bc5fe98acd5',
              key: 'buttonLabel',
              value: $t('onlineShop.themeDesign.componentAttrButtonDefaultSubTitle'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTextPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrButtonText'),
              },
            },
            {
              type: 'AttributeComponentLinkSelect',
              settingId: 'cc90a7dd-fce5-4e7d-a81c-18eeeb78dc07',
              key: 'link',
              value: {},
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrButtonLink'),
              },
            },
            {
              type: 'AttributeComponentSelect',
              settingId: '9321351b-f253-4ab3-a9dc-6f0b4798c79f',
              key: 'outlineButtonStyle',
              value: 'primary',
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrButtonStyle'),
                options: [
                  {
                    label: $t('onlineShop.themeDesign.componentAttrPrimaryButton'),
                    value: 'primary',
                  },
                  {
                    label: $t('onlineShop.themeDesign.componentAttrMinorButton'),
                    value: 'outline',
                  },
                ],
              },
            },
            {
              type: 'AttributeComponentSlider',
              settingId: 'bd3f51c3-22ce-4072-a3fe-0cec811947ac',
              key: 'imageOverlayOpacity',
              value: 1,
              props: {
                label: $t('onlineShop.themeDesign.componentAttrImgTransparency'),
                min: 0,
                max: 1,
                step: 0.1,
              },
            },
          ],
          disabled: false,
        },
        {
          blockId: 'slide-03',
          type: 'slide',
          label: 'item',
          isDisable: true,
          isDraggable: true,
          isRemove: true,
          icon: 'picture',
          settings: [
            {
              type: 'AttributeComponentColorPicker',
              settingId: 'sdf2129c5-11f7-42209-230c-es533aad23413',
              key: 'headingColor',
              value: 'rgba(0, 0, 0, 1)',
              props: {
                label: $t('onlineShop.common.titleColor'),
              },
            },
            {
              type: 'AttributeComponentImageUpload',
              settingId: 'sdf129c5-11f7-4209-230c-es53aad234132',
              key: 'imageUrl',
              value: '',
              props: {
                label: $t('onlineShop.themeDesign.componentAttrSliderImage'),
              },
            },
            {
              type: 'AttributeComponentCheckbox',
              settingId: 'ed6129c5-11f7-4209-860c-ee53aad44864',
              key: 'isShowText',
              value: true,
              props: {
                label: $t('onlineShop.themeDesign.componentAttrsliderTextShow'),
              },
            },
            {
              type: 'AttributeComponentColorPicker',
              settingId: '69d2abf6-b818-41ee-b290-35649e1c22f0',
              key: 'textBoxBgColor',
              value: '#fff',
              props: {
                label: $t('onlineShop.themeDesign.globalTextBgcColor'),
              },
            },
            {
              type: 'AttributeComponentInput',
              settingId: 'a3da2a58-148b-420c-9326-b0515c63f037',
              key: 'heading',
              value: $t('onlineShop.themeDesign.componentAttrSlideDefaultTitle'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrTitle'),
              },
            },
            {
              type: 'AttributeComponentSlider',
              settingId: 'eb530d38-550d-4a71-9a7d-81f7396581cc',
              key: 'headingSize',
              value: 14,
              props: {
                label: $t('onlineShop.themeDesign.globalFontSize'),
                min: 12,
                max: 38,
                step: 2,
              },
            },
            {
              type: 'AttributeComponentTextarea',
              settingId: '00c1ec1c-5720-41db-bac5-d4c161fd59b2',
              key: 'subheading',
              value: $t('onlineShop.themeDesign.componentAttrSlideDefaultSubTitle'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrTwoTitle'),
              },
            },
            {
              type: 'AttributeComponentInput',
              settingId: 'd24f72b9-c5da-4728-aa87-9bc5fe98acd5',
              key: 'buttonLabel',
              value: $t('onlineShop.themeDesign.componentAttrButtonDefaultSubTitle'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTextPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrButtonText'),
              },
            },
            {
              type: 'AttributeComponentLinkSelect',
              settingId: 'cc90a7dd-fce5-4e7d-a81c-18eeeb78dc07',
              key: 'link',
              value: {},
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrButtonLink'),
              },
            },
            {
              type: 'AttributeComponentSelect',
              settingId: '9321351b-f253-4ab3-a9dc-6f0b4798c79f',
              key: 'outlineButtonStyle',
              value: 'primary',
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrSelectPlaceholder'),
                label: $t('onlineShop.themeDesign.componentAttrButtonStyle'),
                options: [
                  {
                    label: $t('onlineShop.themeDesign.componentAttrPrimaryButton'),
                    value: 'primary',
                  },
                  {
                    label: $t('onlineShop.themeDesign.componentAttrMinorButton'),
                    value: 'outline',
                  },
                ],
              },
            },
            {
              type: 'AttributeComponentSlider',
              settingId: 'bd3f51c3-22ce-4072-a3fe-0cec811947ac',
              key: 'imageOverlayOpacity',
              value: 1,
              props: {
                label: $t('onlineShop.themeDesign.componentAttrImgTransparency'),
                min: 0,
                max: 1,
                step: 0.1,
              },
            },
          ],
          disabled: false,
        },
      ],
    },
    {
      sectionId: '8905e8c7-10f2-4647-a337-b089cd44722c',
      type: 'layout-footer',
      label: $t('onlineShop.themeDesign.componentFooter'),
      imageUrl: 'https://b.yzcdn.cn/i18n-b/59fe13674ed9e4a4c6f66bf4c9efb50b.png',
      icon: 'sound',
      blockAddType: 'footer',
      categoryValue: null,
      isDisable: false,
      isDraggable: true,
      isRemove: true,
      settings: [
        {
          type: 'AttributeComponentColorPicker',
          settingId: 'c0eb78f0-a824-44e0-8dfa-37f38508ec54',
          key: 'colorBg',
          value: '#FFF',
          props: {
            label: $t('onlineShop.themeDesign.componentAttrBgc'),
          },
        },
        {
          type: 'AttributeComponentColorPicker',
          settingId: '3ec3a4e5-d824-4966-b0ce-8cb195a8b628',
          key: 'colorText',
          value: '#FFF',
          props: {
            label: $t('onlineShop.themeDesign.componentAttrTextColor'),
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: '5d6d07f8-6d09-4fa5-b0f5-2031d52d654b',
          key: 'marginTop',
          value: 16,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrTopOuterMargin'),
            min: 0,
            max: 32,
            step: 4,
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: '589521b9-480f-408a-8ed1-3c75f1cbb8e2',
          key: 'paddingTop',
          value: 16,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrTopPadding'),
            min: 0,
            max: 32,
            step: 4,
          },
        },
        {
          type: 'AttributeComponentSlider',
          settingId: '7ebfe1fd-3adc-41d6-8890-cc5f5dd6a40f',
          key: 'paddingBottom',
          value: 16,
          props: {
            label: $t('onlineShop.themeDesign.componentAttrButtonPadding'),
            min: 0,
            max: 32,
            step: 4,
          },
        },
        {
          type: 'AttributeComponentInput',
          settingId: '1f42dd49-4d64-4b6e-a997-2ebb0221f614',
          key: 'emailHeading',
          value: $t('onlineShop.themeDesign.componentAttrSubscriptionDefaultTitle'),
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrSecondaryTitle'),
          },
        },
        {
          type: 'AttributeComponentTextarea',
          settingId: '073bb828-18e8-4957-8dcf-1c65b6d418dc',
          key: 'emailSunHeading',
          value: $t('onlineShop.themeDesign.componentAttrSubscriptionSubDefaultTitle'),
          props: {
            placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
            label: $t('onlineShop.themeDesign.componentAttrSecondarySubTitle'),
          },
        },
      ],
      blocks: [
        {
          id: 'd5eccfa1-6853-47e7-9f10-9714260c5f84',
          label: $t('onlineShop.themeDesign.menuTitle'),
          type: 'footer',
          iconComponent: IconExpand,
          isRemove: true,
          settings: [
            {
              type: 'AttributeComponentInput',
              key: 'title',
              value: $t('onlineShop.themeDesign.componentAttrBarDefault'),
              props: {
                placeholder: $t('onlineShop.themeDesign.componentAttrTitlePlaceholder'),
                label: $t('onlineShop.nav.menuTitle'),
              },
            },
            {
              type: 'AttributeComponentFooterMenuSelect',
              key: 'items',
              value: $t('onlineShop.themeDesign.componentAttrBarDefault'),
              props: {
                placeholder: $t('onlineShop.themeDesign.menuSelectionPlaceholder'),
                label: $t('onlineShop.themeDesign.menuTitle'),
              },
            },
          ],
          disabled: false,
        },
      ],
      blockOrder: ['d5eccfa1-6853-47e7-9f10-9714260c5f84'],
    },
  ],
})

export const postMessage = params => {
  const iframe = document.getElementById('design-content__iframe')
  iframe && iframe.contentWindow.postMessage(params, '*')
}

const createStack = () => {
  var list = []
  return {
    /**
     * 入栈
     * @param {*} data
     */
    push(data) {
      list.push(data)
    },

    /**
     * 出栈
     */
    pop() {
      return list.pop()
    },

    size() {
      return list.length
    },

    empty(type) {
      return type === 'back' ? list.length === 1 : list.length === 0
    },

    clear() {
      list = []
    },

    shift() {
      list.shift()
    },

    peek() {
      return list[list.length - 1]
    },

    getList() {
      return list
    },
  }
}
export const createRecord = () => {
  const undoStack = createStack()
  const rollbackStack = createStack()
  const MAX_LIMIT = 20
  return {
    getTopValue() {
      return undoStack.peek()
    },
    addRecord(data) {
      if (undoStack.size() >= MAX_LIMIT) {
        undoStack.shift()
      }
      undoStack.push(data)
      rollbackStack.clear()
    },
    undoRecord() {
      if (undoStack.empty('back')) return
      const data = undoStack.pop()
      rollbackStack.push(data)
    },
    rollbackRecord() {
      if (rollbackStack.empty()) return
      const data = rollbackStack.pop()
      undoStack.push(data)
    },
    getUndoStack() {
      return undoStack.getList()
    },
    getrollbackStack() {
      return rollbackStack.getList()
    },
  }
}
