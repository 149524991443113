/**
 * OPay流量分析SDK
 * 接口文档：https://wiki.opaydev.com/pages/viewpage.action?pageId=14033280
 * 引用示例：
 *  const Analytics = new OPayAnalytics({
 *    apiUrl: '/saas-api/bms/buried-point/v1/tracker/event',
 *    commonParams: {},
 *    commonEventValues: {},
 *  })
 * 使用示例：
 *  Analytics.pv('LoginPage_PV')  // 登录页面PV
 *  Analytics.mv('LoginPage_LoginButton_MV')  // 登录按钮MV
 *  Analytics.mc('LoginPage_LoginButton_MC')  // 登录按钮MC
 *  Analytics.setCommonParams({})  // 设置公共参数
 *  Analytics.setCommonEventValues({})  // 设置公共时间参数
 */
class OPayAnalytics {
  constructor(options) {
    // this.apiUrl = options.apiUrl || '/saas-api/bms/buried-point/v1/tracker/event'
    this.apiUrl = options.apiUrl || '/saas-bms-api/buried-point/v1/tracker/event'
    this.commonParams = options.commonParams || {}
    this.commonEventValues = options.commonEventValues || {}
  }

  /**
   * 获取随机ID
   * @returns
   */
  _createRandomId() {
    return (
      (Math.random() * 10000000).toString(16).slice(0, 4) +
      '-' +
      new Date().getTime() +
      '-' +
      Math.random().toString().slice(2, 7)
    )
  }

  /**
   * 获取OPay TraceId
   * @returns
   */
  _getOPayTid() {
    const storage = window.sessionStorage
    let opayTid = storage.getItem('opayTid')
    if (opayTid) {
      return opayTid
    } else {
      opayTid = this._createRandomId()
      storage.setItem('opayTid', opayTid)
      return opayTid
    }
  }

  /**
   * 获取OPay DeviceId
   * @returns
   */
  _getOPayDid() {
    const storage = window.sessionStorage
    let opayDid = storage.getItem('opayDid')
    if (opayDid) {
      return opayDid
    } else {
      opayDid = this._createRandomId()
      storage.setItem('opayDid', opayDid)
      return opayDid
    }
  }

  /**
   * 判断是否为移动端
   * @returns
   */
  _isMobile() {
    return navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  }

  /**
   * 设置通用自定义参数
   * @param {Object} commonEventValues
   */
  setCommonEventValues(commonEventValues) {
    this.commonEventValues = { ...this.commonEventValues, ...commonEventValues }
  }

  /**
   * 设置通用参数
   * @param {Object} commonParams
   */
  setCommonParams(commonParams) {
    this.commonParams = { ...this.commonParams, ...commonParams }
  }

  /**
   * 获取Ajax的请求头
   * @returns
   */
  getAjaxRequestHeader() {
    return {
      'Content-Type': 'application/json',
    }
  }

  /**
   * 获取Ajax的请求参数
   * @returns
   */
  getAjaxRequestParams(eventName, eventValues) {
    const eventTime = new Date().getTime().toString()
    return {
      tid: this._getOPayTid(), // traceId
      t: eventTime, // 客户端时间
      p: this.commonParams.platform, // 平台类型
      an: this.commonParams.appName || '', // 应用名称
      av: this.commonParams.versionName || '', // 应用版本
      did:
        this.commonParams.deviceId ||
        ((this.commonEventValues || {}).userInfo || {}).userId ||
        this._getOPayDid(), // 设备id

      typ: 'pandova', // 类型
      es: [
        {
          en: eventName, // 时间名称
          et: eventTime, // 事件产生时间，毫秒
          uid: ((this.commonEventValues || {}).userInfo || {}).userId, // 用户id 非必填
          uno: ((this.commonEventValues || {}).userInfo || {}).phone, // 用户手机号，非必填
          ev: {
            envType: this.commonParams.envType, // 环境类型：测试环境-debug，生产环境-release
            pageUrl: location.pathname + location.search, // 页面URL
            pageName: document.title, // 页面名称
            ...this.commonEventValues,
            ...eventValues,
          },
        },
      ],
    }
  }

  /**
   * 发送数据
   * @param {String} eventName 事件名称
   * @param {Object} eventValues 事件自定义参数
   */
  _sendData(eventName, eventValues = {}) {
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function () {}
    xhr.open('post', this.apiUrl + '?t=' + new Date().getTime(), true)
    const headers = this.getAjaxRequestHeader()
    Object.keys(headers).forEach(key => {
      xhr.setRequestHeader(key, headers[key])
    })
    const params = this.getAjaxRequestParams(eventName, eventValues)
    xhr.send(JSON.stringify(params))
  }

  /**
   * 页面展示
   * @param {String} eventName PV事件名称
   * @param {Object} eventValues PV事件自定义参数
   */
  pv(eventName, eventValues = {}) {
    this._sendData(eventName, { ...eventValues, eventType: 'pv' })
  }

  /**
   * 模块展示
   * @param {String} eventName MV事件名称
   * @param {Object} eventValues MV事件自定义参数
   */
  mv(eventName, eventValues = {}) {
    this._sendData(eventName, { ...eventValues, eventType: 'mv' })
  }

  /**
   * 模块点击
   * @param {String} eventName MC事件名称
   * @param {Object} eventValues MC事件自定义参数
   */
  mc(eventName, eventValues = {}) {
    this._sendData(eventName, { ...eventValues, eventType: 'mc' })
  }
}

export default OPayAnalytics
