/**
 * OPay JSBridge SDK
 * 接口文档：https://doc.opaydev.com/WebFramework.html
 * 引用示例：
 *  const JSBridge = new OPayJsBridge()
 * 使用示例：
 *  JSBridge.getUserInfo()
 */
class OPayJsBridge {
  constructor() {
    if (!OPayJsBridge.instance) {
      OPayJsBridge.instance = this
    }
    return OPayJsBridge.instance
  }

  /**
   * 单例静态方法
   * @returns
   */
  static getInstance() {
    if (!this.instance) {
      return (this.instance = new OPayJsBridge())
    }
    return this.instance
  }

  /**
   * JSBridge调用装饰器
   * @param {String} functionName 方法名称
   * @param {Object} params 方法参数
   * @returns Promise
   */
  _jsBridgeInvokeDecorator(functionName, params) {
    return new Promise((resolve, reject) => {
      const callbackName =
        functionName === 'getUserInfo' ? 'getUserInfo' : `${functionName}_${+new Date()}`
      window[callbackName] = res => {
        res = JSON.parse(res || '{}')
        if ('status' in res) {
          if (res.status === 'true') {
            resolve()
          } else {
            reject()
          }
        } else {
          resolve(res)
        }
        delete window[callbackName]
      }
      const androidFunctionInfo = {
        functionName,
        functionData: JSON.stringify({
          callbackName,
          ...params,
        }),
      }
      const iosFunctionInfo = {
        functionName,
        callbackName,
        functionData: JSON.stringify(params) || null,
      }
      if (window.OPay3PartyContext) {
        window.OPay3PartyContext.handleJsBindings(JSON.stringify(androidFunctionInfo))
      } else {
        if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.OPay3PartyContext
        ) {
          window.webkit.messageHandlers.OPay3PartyContext.postMessage(iosFunctionInfo)
        }
      }
    })
  }

  /**
   * 获取用户信息
   * @returns {Object} { isRoot: false, os: '', appName: '', language: '', versionName: '', deviceId: '', versionCode: '', platform: '', model: '', brand: '' }
   *
   */
  getUserInfo() {
    return this._jsBridgeInvokeDecorator('getUserInfo')
  }

  /**
   * 获取登录信息
   * @returns {Object}  { userInfo: {}, shopInfo: {}, token: '' }
   */
  getLoginInfo() {
    return this._jsBridgeInvokeDecorator('getLoginInfo')
  }

  /**
   * 打开一个新的覆盖页面
   * @param {Object} params { url: '' }
   * @returns
   */
  openPage(params) {
    return this._jsBridgeInvokeDecorator('openPage', params)
  }

  /**
   * 关闭当前页面
   * @returns
   */
  closePage() {
    return this._jsBridgeInvokeDecorator('closePage')
  }

  /**
   * 刷新页面
   * @returns
   */
  refreshPage() {
    return this._jsBridgeInvokeDecorator('refreshPage')
  }

  /**
   * 打开Native Scheme界面
   * @param {Object} params { url: '' }
   * @returns
   */
  targetScheme(params) {
    return this._jsBridgeInvokeDecorator('targetScheme', params)
  }

  /**
   * 系统内置分享
   * @param {Object} params { title: '', content: '', contentUrl: '', fileName: '' }
   * @returns
   */
  share(params) {
    return this._jsBridgeInvokeDecorator('share', params)
  }

  /**
   * 下载文件
   * @param {Object} params { url: '', name: '', note: '', fileType: '' }
   * @returns
   */
  downloadFile(params) {
    return this._jsBridgeInvokeDecorator('downloadFile', params)
  }

  /**
   * 复制内容到粘贴板
   * @param {Object} params  { text: '' }
   * @returns
   */
  copyToClipboard(params) {
    return this._jsBridgeInvokeDecorator('copyToClipboard', params)
  }

  /**
   * 设置页面头部右上角按钮
   * @param {Object} params { text: '', url: '' }
   * @returns
   */
  setMenuData(params) {
    return this._jsBridgeInvokeDecorator('setMenuData', params)
  }

  /**
   * 设置标题
   * @param {Object} params { title: '' }
   * @returns
   */
  setTitle(params) {
    return this._jsBridgeInvokeDecorator('setTitle', params)
  }

  /**
   * 隐藏标题栏
   * @returns
   */
  hideTitleBar() {
    return this._jsBridgeInvokeDecorator('hideTitleBar')
  }

  /**
   * 显示标题栏
   * @returns
   */
  showTitleBar() {
    return this._jsBridgeInvokeDecorator('showTitleBar')
  }

  /**
   * 唤起WhatsApp
   * @param {Object} params { accountNumber: '' }
   * @returns
   */
  callWhatsApp(params) {
    return this._jsBridgeInvokeDecorator('callWhatsApp', params)
  }

  /**
   * 唤起邮箱
   * @param {Object} params { emailAddress: '' }
   * @returns
   */
  callEmail(params) {
    return this._jsBridgeInvokeDecorator('callEmail', params)
  }
}

export default OPayJsBridge
