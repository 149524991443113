import Vue from 'vue'
import Vuex from 'vuex'
import {
  LANGUAGE_TYPE_TEXT_MAP,
  DEFAULT_LANGUAGE,
  LANGUAGE_COUNTRY_TYPE_TEXT_MAP,
  LANGUAGE_TYPE,
  NATION_TYPE,
} from '@/constants/common'
import { changeLanguage } from '@/locales'
import { localStorage, PortalAnalytics, MerchantAnalytics } from '@/utils'
import rsaUtil from '@/utils/rsa-util'
import { getShopList, login, getPublicKeyForNoToken } from '@/api/account'
import themeDesign from './module/theme-design.js'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    themeDesign,
  },
  state: {
    userInfo: {},
    shopInfo: {},
    shopList: [],
    language: DEFAULT_LANGUAGE,
    isScreenXs: false,
    shopPreviewPopoverVisible: true,
    badgeNumber: 0,
    accessToken: '',
    countryCode: NATION_TYPE.NIGERIA,
    countryCodeForIP: '',
    adminBannerList: {},
    portalBannerList: {},
    checkoutShopStatus: false,
  },

  getters: {
    checkoutShopStatus(state) {
      return state.checkoutShopStatus
    },
  },

  mutations: {
    setCountryCodeForIP: (state, countryCodeForIP) => {
      // window.$sentry.setTag({ key: 'country', value: countryCodeForIP })
      state.countryCodeForIP = countryCodeForIP
      localStorage.set('countryCodeForIP', countryCodeForIP)
    },
    setUserInfo: (state, userInfo) => {
      // window.$sentry.setUser(userInfo)
      state.userInfo = userInfo
      localStorage.set('userInfo', userInfo)
      PortalAnalytics.setCommonEventValues({ userInfo })
      MerchantAnalytics.setCommonEventValues({ userInfo })
    },
    setShopInfo: (state, shopInfo) => {
      state.shopInfo = shopInfo
      localStorage.set('shopInfo', shopInfo)
      PortalAnalytics.setCommonEventValues({ shopInfo })
      MerchantAnalytics.setCommonEventValues({ shopInfo })
    },
    setShopList: (state, shopList) => {
      state.shopList = shopList
      localStorage.set('shopList', shopList)
    },
    setAccessToken: (state, accessToken) => {
      state.accessToken = accessToken
      localStorage.set('accessToken', accessToken)
    },
    setLanguage: (state, languageCode) => {
      const language = LANGUAGE_TYPE_TEXT_MAP[languageCode] ? languageCode : DEFAULT_LANGUAGE
      state.language = language
      localStorage.set('language', language)
      if (!LANGUAGE_COUNTRY_TYPE_TEXT_MAP[`${state.countryCode}-${state.language}`]) {
        // 判断语言
        if (language === LANGUAGE_TYPE.ARABIC) {
          state.countryCode = NATION_TYPE.EGYPT
          localStorage.set('countryCode', NATION_TYPE.EGYPT)
        }
        if ([LANGUAGE_TYPE.ENGLISH, LANGUAGE_TYPE.CHINESE].includes(language)) {
          state.countryCode = NATION_TYPE.NIGERIA
          localStorage.set('countryCode', NATION_TYPE.NIGERIA)
        }
      }
      changeLanguage(language)
    },
    setMobileType: (state, isScreenXs) => {
      state.isScreenXs = isScreenXs
    },
    setBadgeNumber: (state, number) => {
      state.badgeNumber = number
    },
    setShopPreviewPopoverVisible: (state, shopPreviewPopoverVisible) => {
      state.shopPreviewPopoverVisible = shopPreviewPopoverVisible
      localStorage.set('shopPreviewPopoverVisible', shopPreviewPopoverVisible)
    },
    setCountryCode: (state, code) => {
      if (!LANGUAGE_COUNTRY_TYPE_TEXT_MAP[`${code}-${state.language}`]) {
        return
      }
      state.countryCode = code
      localStorage.set('countryCode', code)
    },
    setAdminBannerList(state, data) {
      state.adminBannerList = data
    },
    setPortalBannerList(state, data) {
      state.portalBannerList = data
    },
    setCheckoutShopStatus(state, data) {
      state.checkoutShopStatus = data
    },
  },
  actions: {
    // 退出
    logout({ commit }) {
      localStorage.remove('accessToken')
      localStorage.remove('userInfo')
      localStorage.remove('shopInfo')
      commit('setUserInfo', {})
      commit('setShopInfo', {})
      commit('setShopList', [])
    },
    async getShopListAndUpdateCurrentShopInfo({ commit }, shopId) {
      const { data } = await getShopList()
      commit('setShopList', data || [])
      const shopInfo = (data || []).find(item => item.shopId === shopId) || {}
      shopId && commit('setShopInfo', shopInfo)
    },
    async login({ commit }, loginParams) {
      const { mail, password } = loginParams
      const loginResponse = await getPublicKeyForNoToken({ mail })
      const encryptLoginPassword = rsaUtil.encrypt(password, loginResponse.data)
      const { data } = await login({ mail, password: encryptLoginPassword })
      const { token, account } = data || {}
      commit('setUserInfo', account || {})
      commit('setAccessToken', token)
      return account
    },
  },
})
