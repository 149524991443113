<template>
  <div class="portal-layout">
    <op-activity-banner
      banner-store-state-key="portalBannerList"
      business-type="portal_home_top"
    />
    <div class="portal-layout__header">
      <portal-header />
    </div>
    <div class="portal-layout__content">
      <customer-service v-if="!isPandovaApp" />
      <router-view />
    </div>
    <div class="portal-layout__footer">
      <portal-footer />
    </div>
  </div>
</template>

<script>
import { isPandovaApp } from '@/utils'
import CustomerService from './components/customer-service.vue'
import PortalHeader from './components/portal-header.vue'
import PortalFooter from './components/portal-footer.vue'
import { mapState } from 'vuex'
export default {
  name: 'PortalLayout',
  components: {
    PortalHeader,
    PortalFooter,
    CustomerService,
  },
  data() {
    return {
      isPandovaApp: isPandovaApp(),
    }
  },
  computed: {
    ...mapState(['language', 'countryCode']),
  },
}
</script>

<style scoped lang="less">
.portal-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;

  &__header {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    background: #fff;
  }
  &__content {
    flex: 1;
  }
}
</style>
