import { request, METHOD } from '@/utils'
// 登录
export const login = params => {
  return request('/saas-api/account/v1/shop-login/web-admin-login', METHOD.POST, params)
}
// 退出登录
export const logout = params => {
  return request('/saas-api/account/v1/shop-logout/web-admin-logout', METHOD.GET, params, {
    needPreprocessBusinessError: false,
  })
}
// 忘记密码发送验证邮件
export const getCheckMailCode = params => {
  return request('/saas-api/account/v1/shop-login/send-forgot-mail', METHOD.POST, params)
}
// 忘记密码校验账户
export const checkAccount = params => {
  return request('/saas-api/account/v1/shop-login/check-forgot-mail', METHOD.POST, params)
}
// 重置密码
export const resetPassword = params => {
  return request('/saas-api/account/v2/shop-login/web-reset-password', METHOD.POST, params)
}
// 注册
export const register = params => {
  return request('/saas-api/account/v1/shop-login/web-register-user', METHOD.POST, params)
}

// 渠道注册
export const channelRegister = params => {
  return request('/saas-api/account/v1/shop-login/web-channel-register', METHOD.POST, params)
}
// 注册发送验证邮件
export const getRegisterMailCode = params => {
  return request('/saas-api/account/v1/shop-login/send-register-mail', METHOD.POST, params)
}
// 新增店铺
export const addNewShop = params => {
  return request('/saas-api/account/v1/shop-info/web-login-shop-perfect', METHOD.POST, params)
}

// 获取店铺列表
export const getShopList = params => {
  return request('/saas-api/account/v1/shop-info/list', METHOD.GET, params)
}
// 获取登录后获取公钥
export const getPublicKeyWithToken = params => {
  return request('/saas-api/account/v1/shop-user/public-key', METHOD.POST, params)
}
// 获取未登录获取公钥
export const getPublicKeyForNoToken = params => {
  return request('/saas-api/account/v1/shop-login/web-public-key', METHOD.POST, params)
}
// 验证员工激活状态
export const checkStaffActive = params => {
  return request('/saas-api/account/v1/shop-user/staff-active', METHOD.POST, params)
}
// 注册是否需要降级 true需要 false不需要
export const checkRegisterDegraded = params => {
  return request('/saas-api/account/v1/shop-login/register-degraded', METHOD.GET, params)
}
// 是否开通过opay支付
export const checkPaymentOpened = () => {
  return request('/saas-api/account/v1/merchant/checkout-opened', METHOD.GET)
}
