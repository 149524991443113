import { getOssImageCompressUrl } from '@/utils/image-compress'

const getCompressImgUrl = imgOption => {
  const option = {
    ...imgOption,
  }

  return getOssImageCompressUrl(option)
}

const updateImgSrc = (el, binding) => {
  const timer = setTimeout(() => {
    const option = {
      ...binding.value,
      width: el.width,
      height: el.height,
    }
    const newSrc = getCompressImgUrl(option)
    el.setAttribute('src', newSrc)
    clearTimeout(timer)
  })
}

export default {
  name: 'compress',
  inserted: updateImgSrc,
  update: updateImgSrc,
}
