import { render, staticRenderFns } from "./account-layout.vue?vue&type=template&id=20bc98e0&scoped=true&"
import script from "./account-layout.vue?vue&type=script&lang=js&"
export * from "./account-layout.vue?vue&type=script&lang=js&"
import style0 from "./account-layout.vue?vue&type=style&index=0&id=20bc98e0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20bc98e0",
  null
  
)

export default component.exports