<template>
  <div
    class="admin-header-right-content"
    style
  >
    <span
      class="admin-header-right-content__mall"
      @click="goMall"
    >
      <a-icon
        class="admin-header-right-content__icon"
        type="desktop"
      />
      <div
        v-if="shopPreviewPopoverVisible"
        class="ant-popover ant-popover-placement-bottomRight admin-header-right-content__mall__tip-wrap"
      >
        <div class="ant-popover-content">
          <div class="ant-popover-arrow"></div>
          <div class="ant-popover-inner">
            <div class="ant-popover-inner-content admin-header-right-content__mall__content">
              <a-icon
                class="admin-header-right-content__icon admin-header-right-content__mall__close-icon"
                type="close-circle"
                @click.stop="closePreviewPopoverTip"
              />
              <div class="admin-header-right-content__mall__text">{{ $t('home.shopMallPreview') }}</div>
            </div>
          </div>
        </div>
      </div>
    </span>

    <a-divider type="vertical" />
    <span
      class="admin-header-right-content__message"
      @click="goNotificationList"
    >
      <a-icon
        class="admin-header-right-content__icon admin-header-right-content__icon-bell"
        type="bell"
      />
      <span
        v-if="messageNumber > 0"
        class="admin-header-right-content__message__count"
      >{{ messageNumber }}</span>
    </span>
    <admin-header-account class="admin-header-right-content__header-account" />
    <a-drawer
      v-if="showMessage"
      width="420"
      :title="$t('setting.notifications.title')"
      placement="right"
      :visible="showMessage"
      @close="closeMessageDrawer"
    >
      <notification-list></notification-list>
    </a-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AdminHeaderAccount from './admin-header-account.vue'
import { getMessageUnreadNum } from '@/api/setting'
import { getMallPageAbsolutePath } from '@/utils/index'
import NotificationList from '@/views/setting/notification-list'
import { LANGUAGE_COUNTRY_ID } from '@/constants/common'
export default {
  name: 'AdminHeaderRightContent',
  components: {
    AdminHeaderAccount,
    NotificationList,
  },
  data() {
    return {
      popoverVisible: true,
      showMessage: false,
    }
  },
  computed: {
    ...mapState([
      'badgeNumber',
      'shopInfo',
      'shopPreviewPopoverVisible',
      'isScreenXs',
      'countryCode',
      'language',
    ]),
    messageNumber() {
      if (this.badgeNumber > 99) return 99
      return this.badgeNumber
    },
  },
  created() {
    this.getMessageUnreadNum()
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement
    },
    closeMessageDrawer() {
      this.showMessage = false
    },
    goNotificationList() {
      this.getMessageUnreadNum()
      if (this.isScreenXs) {
        this.$router.push({ path: '/setting/notification-list' })
      } else {
        this.showMessage = !this.showMessage
      }
    },
    closePreviewPopoverTip() {
      this.$store.commit('setShopPreviewPopoverVisible', false)
    },
    async getMessageUnreadNum() {
      const { data } = await getMessageUnreadNum({
        countryLngType: LANGUAGE_COUNTRY_ID[`${this.shopInfo.country}-${this.language}`],
      })
      this.$store.commit('setBadgeNumber', data || 0)
    },
    goMall() {
      this.closePreviewPopoverTip()
      const domainUrl = getMallPageAbsolutePath()
      window.open(domainUrl, '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
.admin-header-right-content {
  padding-right: 20px;
  &__icon {
    margin: 0 6px;
    font-size: 18px;
    color: #8991a4;
    vertical-align: middle;
    cursor: pointer;
  }
  &__icon-bell {
    margin-right: 20px;
  }
  &__header-account {
    display: inline-block;
  }
  &__message {
    position: relative;
    &__count {
      position: absolute;
      bottom: -10px;
      left: 15px;
      width: 20px;
      height: 20px;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      background: red;
      border-radius: 50%;
      transform: scale(0.7);
    }
  }
  &__mall {
    position: relative;
    /deep/ .ant-popover {
      top: 30px;
    }

    &__tip-wrap {
      position: absolute;
      left: -106px;
      width: 140px;
    }
    &__content {
      position: relative;
    }
    &__close-icon {
      position: absolute;
      top: 4px;
      right: -2px;
      font-size: 14px;
      color: #8991a4;
    }

    &__text {
      margin-top: 6px;
    }
  }
}
/deep/ .ant-drawer-body {
  height: calc(100% - 55px);
}
.ant-drawer {
  height: calc(100% - 64px);
  margin-top: 64px;
}
</style>
