import store from '@/store'
import { DEFAULT_LANGUAGE, NATION_TYPE, MAX_LIVE_DURATION } from '@/constants/common'
import router from '@/router/index'
import { omit } from 'lodash'
import { getCountryCode, getCustomerBanner } from '@/api/common'
import OPayAnalytics from './opay-analytics'
import OPayJsBridge from './opay-js-bridge'
import { isProduction, getOPayAnalyticsPlatform, isPandovaApp } from './environment'
import { localStorage } from './storage'
export * from './request'
export * from './validator'
export * from './environment'
export * from './date-formatter'
export * from './money-formatter'
export * from './storage'

/**
 *
 * @param {String} url 文件路径
 * @returns  文件
 * @example
 * const result = downFile('https://xxx.xx.xlsx')
 */
export const downFile = url => {
  if (!url) {
    return
  }
  const isSupportDownload = 'download' in document.createElement('a')
  if (!isSupportDownload) {
    window.open(url)
    return
  }
  const fileName = url.split('/').pop()
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * 去除空属性（0除外）
 * @param {Object} obj 对象
 * @returns
 * @example
 * const result = removeEmptyField({ a: undefined, b: null, c: -1, d: '', e: [], f: '1' })
 * // =>
 * {f: '1'}
 */
export const removeEmptyField = (obj, numberFieldList = []) => {
  const result = JSON.parse(JSON.stringify(obj))
  Object.keys(result).forEach(key => {
    const emptyValueList = numberFieldList.includes(key) ? [null, ''] : [-1, null, '']
    if (
      emptyValueList.includes(result[key]) ||
      (Array.isArray(result[key]) && result[key].length === 0)
    ) {
      delete result[key]
    }
  })
  return result
}

/**
 * 生成UUID
 * @returns UUID字符串
 * const result = generateUUID()
 * // =>
 * a1ea7884-b188-492e-a2e4-f5880d0d5f2f
 */
export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, text => {
    var random = (Math.random() * 16) | 0
    var value = text === 'x' ? random : (random & 0x3) | 0x8
    return value.toString(16)
  })
}

/**
 * 用户是否有权限
 * @param {String} sign 权限Code
 * @returns
 */
export const hasPermission = sign => {
  const permissionSignList = localStorage.get('functionPermissionSignList', [])
  return permissionSignList.includes(sign)
}

/*
 * 官网的埋点分析对象
 */
export const PortalAnalytics = new OPayAnalytics({
  commonParams: {
    appName: 'SP_PORTAL', // 应用名称
    envType: isProduction() && process.env.NODE_ENV === 'production' ? 'release' : 'debug', // 环境类型
    platform: getOPayAnalyticsPlatform(), // 平台类型
  },
})

/*
 * 商家后台的埋点分析对象
 */
export const MerchantAnalytics = new OPayAnalytics({
  commonParams: {
    appName: 'SP_MERCHANT', // 应用名称
    envType: isProduction() && process.env.NODE_ENV === 'production' ? 'release' : 'debug', // 环境类型
    platform: getOPayAnalyticsPlatform(), // 平台类型
  },
})

/**
 * 对象转url参数
 * @returns url参数
 * const result ={ id:1, name:'abc' }
 * // =>
 * id=1&name=abc
 */
export const objToQuery = data => {
  const temp = []
  for (const prop in data || {}) {
    temp.push(`${prop}=${data[prop]}`)
  }
  return `${temp.join('&')}`
}

/**
 * 获取跳转商城页面绝对路径
 * @param {Object}
 * @returns 商城地址
 *
 * 线上测试环境域名（ip）写 http://118.31.168.3
 * 线上测试环境shopId通过host url获取（线上测试环境没有真实域名，不能根据域名解析shopId）
 *
 * const result =getMallPageAbsolutePath({ path:'/online-shop/shop-design',query:{themeId:1232323} })
 * // =>
 * {商城域名地址}/online-shop/shop-design?themeId=1232323
 */
export const getMallPageAbsolutePath = params => {
  const { path = '', query } = params || {}
  let queryString = Object.entries(query || {})
    .map(item => `${item[0]}=${item[1]}`)
    .join('&')
  queryString = queryString ? `?${queryString}` : ''
  let domain = (store.state.shopInfo || {}).domainUrl
  const swimlane = (location.host.match(/^merchant\.(s\d+)\.pandova\.site$/) || [])[1]
  if (swimlane) {
    domain = domain.replace('pandova.site', `${swimlane}.pandova.site`)
  }
  const protocol = domain.includes('.pandova.shop') ? 'https:' : 'http:'
  return `${protocol}//${domain}${path}${queryString}`
}
/**
 * 打开新tab
 * @param {object} params
 * @param {string} params.path  跳转路径
 * @param {object} [params.query] 路径参数
 */
export const windowOpen = (params = { path: '', query: {} }) => {
  window.open(`${params.path}?${objToQuery(params.query)}`)
}
/**
 * OPay的JsBridge对象
 */
export const JSBridge = new OPayJsBridge()

/**
 * list转tree
 * @returns 生成的tree数组
 * const result = [{id:1,parentId:0,sort:1},{id:2,parentId:1,sort:1},{id:3,parentId:1,sort:2}]
 * // =>
 * [{id:1,parentId:0,sort:1,children:[{id:2,parent:1,sort:1},{id:3,parent:1,sort:2}]}]
 */
export const listToTree = (list, parentKey, parentId) => {
  return list
    .filter(item => item[parentKey] === parentId)
    .map(item => ({
      ...item,
      children: listToTree(list, parentKey, item.id),
    }))
}

/**
 * tree转list
 * @returns 生成的tree数组
 * const result = [{id:1,parentId:0,sort:1},{id:2,parentId:1,sort:1},{id:3,parentId:1,sort:2}]
 * // =>
 * [{id:1,parentId:0,sort:1,children:[{id:2,parent:1,sort:1},{id:3,parent:1,sort:2}]}]
 */
export const treeToList = (tree, parentKey, parentId) => {
  const data = []
  const childrenHandle = tree => {
    tree.forEach(item => {
      data.push(omit(item, 'children'))
      if ((v.children || []).length) {
        childrenHandle(item.children)
      }
    })
  }
  childrenHandle(tree)
  return data
}

/**
 * 获取URL的参数值
 * @param {String} url 指定URL
 * @param {String} key 参数key
 * @returns 指定参数值
 * @example
 * const result = getUrlParam('https://blog.csdn.net/weixin_30505751/article/details/95270464?hello=world', 'hello')
 * // =>
 * world
 */
export const getUrlParam = (url, key) => {
  const search = url.split('?')[1] || ''
  const regExp = new RegExp('(^|&)' + key + '=([^&]*)(&|$)')
  const result = search.match(regExp)
  if (result) return result[2]
  return null
}

/**
 * 返回上一层
 * 如果有历史栈 go（-1）否则 返回home页
 */
export const goBack = () => {
  if (window.history.length > 2) {
    router.go(-1)
    return
  }
  // 兼容手机端 有历史go（-1） 没有历史记录 关闭当前页面
  if (isPandovaApp()) {
    router.go(-1) || JSBridge.closePage()
    return
  }
  router.push({ path: '/home' })
}
/**
 * 返回上一层
 * 如果有历史栈 go（-1）否则 返回home页  ，兼容手机端（直接关闭页面，没有操作成功toasts提示）,提示之后再关闭页面。
 */
export const goBackForDelayClose = () => {
  if (window.history.length > 2) {
    router.go(-1)
    return
  }
  if (isPandovaApp()) {
    setTimeout(() => {
      JSBridge.closePage()
    }, 1500)
    return
  }
  router.push({ path: '/home' })
}

// 兼容app <op-link target="_blank" > <a target="_blank" /> 跳转打不开问题
export const openWindow = url => {
  if (isPandovaApp()) {
    JSBridge.openPage({ url })
    return
  }
  window.open(url)
}

/**
 * 获取渠道信息
 * 如果创建时间大于30天，清除渠道信息，渠道信息返回空对象
 */
export const getPromotionChannelInfo = () => {
  const promotionChannelInfo = localStorage.get('promotionChannelInfo') || {}
  if (!promotionChannelInfo) return {}
  const createDays = Date.now() - promotionChannelInfo.createTime
  if (createDays > 24 * 3600 * 1000 * MAX_LIVE_DURATION) {
    this.removeChannelInfo()
    return {}
  }
  return omit(promotionChannelInfo, ['createTime'])
}

/**
 * 根据IP设置语言和国家
 * @param {String} ip IP地址，可选
 */
export const setLanguageAndCountry = async ip => {
  let countryCode = localStorage.get('countryCode')
  let countryCodeForIP = ''
  const language = localStorage.get('language', DEFAULT_LANGUAGE)
  store.commit('setLanguage', language)
  // 访问页面未调用过ip获取国家 &&  WEB内 需要请求ip获取国家接口设置语言
  if (!countryCode && !isPandovaApp()) {
    try {
      const res = await getCountryCode(removeEmptyField({ ipAddress: ip }))
      countryCodeForIP = res.data || ''

      countryCode = res.data || NATION_TYPE.NIGERIA
    } catch (error) {
      countryCode = NATION_TYPE.NIGERIA
    }
  } else {
    const res = await getCountryCode()
    countryCodeForIP = res.data || ''
  }
  store.commit('setCountryCodeForIP', countryCodeForIP)
  store.commit('setCountryCode', countryCode)
}

export const setBannerData = async (propagateSpaceKey, storeCommitKey) => {
  const res = await getCustomerBanner({
    propagateSpaceKey,
  })
  const data = {}
  ;(res.data || []).forEach(item => {
    const propagateInfoData = {}
    ;(item.propagateInfos || []).forEach(item => {
      if (!propagateInfoData[item.languageType]) {
        propagateInfoData[item.languageType] = []
      }
      propagateInfoData[item.languageType].push(item)
    })
    item.propagateInfoData = propagateInfoData
    data[item.businessType] = item
  })
  store.commit(storeCommitKey, data)
}
