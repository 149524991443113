// 员工类型
export const USER_TYPE = {
  SHOP_MANAGER: 1,
  GENERAL_STAFF: 2,
}

// 员工类型文案映射
export const USER_TYPE_TEXT_MAP = {
  [USER_TYPE.SHOP_MANAGER]: $t('setting.selectTextShopManager'),
  [USER_TYPE.GENERAL_STAFF]: $t('setting.selectTextGeneralStaff'),
}

// 员工类型文案映射列表
export const USER_TYPE_TEXT_MAP_LIST = Object.values(USER_TYPE).map(item => {
  return {
    label: USER_TYPE_TEXT_MAP[item],
    value: item,
  }
})

// 消息阅读状态
export const NOTIFICATION_STATUS = {
  UNREAD: 1,
  READ: 2,
}

// 消息阅读状态文案映射
export const NOTIFICATION_STATUS_TEXT_MAP = {
  [NOTIFICATION_STATUS.READ]: $t('setting.selectTextRead'),
  [NOTIFICATION_STATUS.UNREAD]: $t('setting.selectTextUnread'),
}

// 消息阅读状态文案映射列表
export const NOTIFICATION_STATUS_TEXT_MAP_LIST = Object.values(NOTIFICATION_STATUS).map(item => {
  return {
    label: NOTIFICATION_STATUS_TEXT_MAP[item],
    value: item,
  }
})

// 绑定支付账号状态
export const PAYMENT_ACCOUNT_STATUS = {
  TO_OPEN: 1,
  IN_HAND: 2,
  HAVE_OPENED: 3,
  REJECTED: 4,
}

// 绑定支付账号状态文案映射
export const PAYMENT_ACCOUNT_STATUS_TEXT_MAP = {
  [PAYMENT_ACCOUNT_STATUS.TO_OPEN]: $t('setting.payment.toOpen'),
  [PAYMENT_ACCOUNT_STATUS.IN_HAND]: $t('setting.payment.review'),
  [PAYMENT_ACCOUNT_STATUS.HAVE_OPENED]: $t('setting.payment.opened'),
  [PAYMENT_ACCOUNT_STATUS.REJECTED]: $t('setting.payment.reviewFailed'),
}

// 绑定支付账号状态文案映射列表
export const PAYMENT_ACCOUNT_STATUS_TEXT_MAP_LIST = Object.values(PAYMENT_ACCOUNT_STATUS).map(
  item => {
    return {
      label: PAYMENT_ACCOUNT_STATUS_TEXT_MAP[item],
      value: item,
    }
  }
)

// 复合税状态
export const COMPOUND_TAX_STATUS = {
  YES: 1,
  NO: 2,
}

// 复合税状态文案映射
export const COMPOUND_TAX_STATUS_TEXT_MAP = {
  [COMPOUND_TAX_STATUS.YES]: $t('setting.selectTextYes'),
  [COMPOUND_TAX_STATUS.NO]: $t('setting.selectTextNo'),
}

// 复合税状态文案映射列表
export const COMPOUND_TAX_STATUS_TEXT_MAP_LIST = Object.values(COMPOUND_TAX_STATUS).map(item => {
  return {
    label: COMPOUND_TAX_STATUS_TEXT_MAP[item],
    value: item,
  }
})

// 域名状态
export const DOMAIN_STATUS_TYPE = {
  LINKED: 1,
  UNITED: 2,
}

// 域名状态文案映射
export const DOMAIN_STATUS_TYPE_TEXT_MAP = {
  [DOMAIN_STATUS_TYPE.LINKED]: $t('setting.domainList.linked'),
  [DOMAIN_STATUS_TYPE.UNITED]: $t('setting.domainList.united'),
}

// 域名状态文案映射列表
export const DOMAIN_STATUS_TYPE_TEXT_MAP_LIST = Object.values(DOMAIN_STATUS_TYPE).map(item => {
  return {
    label: DOMAIN_STATUS_TYPE_TEXT_MAP[item],
    value: item,
  }
})

// OPay支付状态
export const OPAY_PAYMENT_STATUS = {
  NO_OPEN: 1,
  OPEN_SUCCESS: 2,
  PERSON_PENDING: 3,
  PERSON_FAIL: 4,
  PERSON_SUCCESS: 5,
  COMPANY_SUCCESS: 6,
}

// OPay支付状态文案映射
export const OPAY_PAYMENT_STATUS_TEXT = {
  [OPAY_PAYMENT_STATUS.NO_OPEN]: '未开通',
  [OPAY_PAYMENT_STATUS.OPEN_SUCCESS]: '开通level1成功',
  [OPAY_PAYMENT_STATUS.PERSON_PENDING]: 'level3审核中',
  [OPAY_PAYMENT_STATUS.PERSON_FAIL]: 'level3开通失败',
  [OPAY_PAYMENT_STATUS.PERSON_SUCCESS]: '开通level3成功',
  [OPAY_PAYMENT_STATUS.COMPANY_SUCCESS]: '开通level5成功',
}

// OPay支付状态组件映射
export const OPAY_PAYMENT_STATUS_COMPONENT_MAP = {
  [OPAY_PAYMENT_STATUS.NO_OPEN]: 'NoOpen',
  [OPAY_PAYMENT_STATUS.OPEN_SUCCESS]: 'OpenSuccess',
  [OPAY_PAYMENT_STATUS.PERSON_PENDING]: 'PersonPending',
  [OPAY_PAYMENT_STATUS.PERSON_FAIL]: 'PersonFail',
  [OPAY_PAYMENT_STATUS.PERSON_SUCCESS]: 'PersonSuccess',
  [OPAY_PAYMENT_STATUS.COMPANY_SUCCESS]: 'CompanySuccess',
}

// 配送方式  0:未选择配送方式, 1:免运费, 2:固定运费, 3:自定义区域设置运费)
export const DELIVERY_TYPE = {
  NO_DELIVERY: 0,
  FREE_DELIVERY: 1,
  FIXED_DELIVERY: 2,
  CUSTOM_DELIVERY: 3,
}
// 短信发送方式
export const SMS_SEND_TYPE = {
  MANUAL: 1,
  AUTO: 2,
}

// 短信发送方式文案映射
export const SMS_SEND_TYPE_TEXT_MAP = {
  [SMS_SEND_TYPE.MANUAL]: $t('sms.commandShift'),
  [SMS_SEND_TYPE.AUTO]: $t('sms.animation'),
}

// 短信发送方式文案映射列表
export const SMS_SEND_TYPE_TEXT_MAP_LIST = Object.values(SMS_SEND_TYPE).map(item => {
  return {
    label: SMS_SEND_TYPE_TEXT_MAP[item],
    value: item,
  }
})
