<template>
  <a-dropdown
    class="admin-header-account"
    :trigger="['click']"
    placement="bottomRight"
  >
    <div>
      <a-avatar
        class="admin-header-account__head-img"
        size="small"
        icon="user"
      />
    </div>
    <div
      slot="overlay"
      class="admin-header-account__user-info-card"
    >
      <a-avatar icon="user" />
      <div class="admin-header-account__user-name">{{ userInfo.firstName }}</div>
      <div class="admin-header-account__mail">{{ userInfo.mail }}</div>
      <a-divider />
      <a-row
        v-if="!hasAccountSetting"
        type="flex"
        justify="space-between"
        align="middle"
        class="admin-header-account__list-row"
        @click.native="goAccountSetting"
      >
        <a-col>{{ $t('home.accountSetting') }}</a-col>
        <a-col>
          <a-icon type="right" />
        </a-col>
      </a-row>
      <a-popover
        v-model="popoverVisible"
        placement="leftTop"
        overlay-class-name="admin-header-account__language-popover"
      >
        <div slot="content">
          <op-language-dropdown-menu @change="handleChangeLanguage" />
        </div>
        <a-row
          type="flex"
          justify="space-between"
          align="middle"
          class="admin-header-account__list-row"
        >
          <a-col>{{ $t('home.adminLanguage') }}</a-col>
          <a-col>
            <a-icon type="right" />
          </a-col>
        </a-row>
      </a-popover>
      <a-divider />
      <div
        class="admin-header-account__logout"
        @click="logout"
      >{{ $t('home.logout') }}</div>
    </div>
  </a-dropdown>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { logout } from '@/api/account'
import { changeLanguage } from '@/api/common'
import { LANGUAGE_TYPE_TEXT_MAP_LIST, DEFAULT_LANGUAGE } from '@/constants/common'
export default {
  name: 'AdminHeaderAccount',
  props: {
    hasAccountSetting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LANGUAGE_TYPE_TEXT_MAP_LIST,
      DEFAULT_LANGUAGE,
      popoverVisible: false,
    }
  },
  computed: {
    ...mapState(['userInfo', 'isScreenXs', 'language']),
  },
  methods: {
    ...mapMutations(['setLanguage', 'setCountryCode']),
    goAccountSetting() {
      this.$router.push({ path: '/setting/account-setting' })
    },
    async handleChangeLanguage(languageCountry) {
      const { languageCode, countryCode } = languageCountry
      this.setLanguage(languageCode)
      this.setCountryCode(countryCode)
      await changeLanguage({ languageCode })
      window.location.reload()
    },
    async logout() {
      try {
        await logout()
      } finally {
        await this.$store.dispatch('logout')
        this.$router.push({ path: '/account/login' })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.admin-header-account {
  &__head-img {
    cursor: pointer;
  }
  &__name {
    padding: 0 10px;
    font-size: 16px;
    cursor: pointer;
  }
  &__user-info-card {
    width: 240px;
    padding: 24px 0 12px 0;
    text-align: center;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.1);
    /deep/ .ant-divider-horizontal {
      margin: 8px 0;
    }
  }

  /deep/ .ant-popover-inner-content {
    padding: 0;
  }
  &__language-item {
    width: 100px;
    padding: 6px 0;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #e8e8e8;
    &:hover {
      color: @primary-color;
      background-color: #f7f8fa;
    }
    &--active {
      color: @primary-color;
      background-color: #f7f8fa;
    }
  }
  &__user-name {
    margin-top: 8px;
  }
  &__mail {
    margin-top: 8px;
    font-size: 12px;
    color: #6d7b98;
  }
  &__logout {
    color: @primary-color;
    cursor: pointer;
  }
  &__list-row {
    padding: 10px 14px;
    color: #323233;
    cursor: pointer;
    &:hover {
      background-color: #f7f8fa;
    }
  }
}
</style>
<style lang="less">
.admin-header-account__language-popover .ant-popover-inner-content {
  padding: 0;
}
</style>
