import Decimal from 'decimal.js-light'
import store from '@/store'

/**
 * 元转分
 * @param {Number} money 以元位单位的钱数
 * @returns
 * @example
 * const result = formatNumber(200)
 * //=> 200,000
 */
export const yuanToFen = money => {
  if (!money) {
    return 0
  }
  return +new Decimal(+money).mul(100).valueOf()
}

/**
 * 分转元
 * @param {Number} money 分位单位的钱数
 * @returns
 */
export const fenToYuan = money => {
  if (!money) {
    return 0
  }
  return +new Decimal(+money).mul(0.01).valueOf()
}

/**
 *
 * @param {Number} number 整数、浮点数
 * @param {Number} decimals  保留小数点位数
 * @param {*} decimalPoint    小数点
 * @param {*} thousandsSep  千分位分割符
 * @param {*} scale  进位方式  'ceil' 向上取,'floor'向下取,'round' 四舍五入
 * @returns  整数或浮点数
 * @example
 * const result = formatNumber(200000)
 * //=> 200,000
 */
export const formatNumber = (
  number,
  decimals = 0,
  decimalPoint = '.',
  thousandsSep = ',',
  scale = 'round'
) => {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const decimalDigit = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
  const dec = typeof decimalPoint === 'undefined' ? '.' : decimalPoint
  let s = ''
  const toFixedFix = function (n, decimalDigit) {
    const k = Math.pow(10, decimalDigit)

    return (
      '' +
      parseFloat(
        Math[scale](parseFloat((n * k).toFixed(decimalDigit * 2))).toFixed(decimalDigit * 2)
      ) /
        k
    )
  }
  s = (decimalDigit ? toFixedFix(n, decimalDigit) : '' + Math.round(n)).split('.')
  const re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }

  if ((s[1] || '').length < decimalDigit) {
    s[1] = s[1] || ''
    s[1] += new Array(decimalDigit - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

/**
 * @param {Number} 钱数 系统整体保留两位小数
 * @returns 保留两位小数点钱数
 * @example
 * const result = formatMoney(35000)
 * //=> NGN 35,000.00
 */
export const formatMoney = money => {
  const monetary = store.state.shopInfo.currency
  const value = formatNumber(fenToYuan(money), 2)
  return `${monetary} ${value}`
}
