import store from 'store'
import ls from 'store/storages/localStorage'
import ss from 'store/storages/sessionStorage'
import cs from 'store/storages/cookieStorage'
import expirePlugin from 'store/plugins/expire'

const localStore = store.createStore(ls)
const sessionStore = store.createStore(ss)
const cookieStore = store.createStore(cs)

localStore.addPlugin(expirePlugin)
sessionStore.addPlugin(expirePlugin)
cookieStore.addPlugin(expirePlugin)

/**
 * 使用示例：
 * localStorage.set('user', { name:'Marcus' }) // 设置user数据
 * localStorage.get('user') // 获取user数据
 * localStorage.get('user', {}) // 获取user数据，使用{}兜底
 * localStorage.remove('user') // 移除user数据
 * localStorage.clearAll() // 清除所有数据
 * localStorage.set('user', { name:'Marcus' }, Date.now() + 86400) // 设置user数据，并设置过期时间为1天
 * localStorage.getExpiration('user') // 获取user数据的过期时间戳
 */
export const localStorage = localStore

/**
 * 使用示例：
 * sessionStorage.set('user', { name:'Marcus' }) // 设置user数据
 * sessionStorage.get('user') // 获取user数据
 * sessionStorage.get('user', {}) // 获取user数据，使用{}兜底
 * sessionStorage.remove('user') // 移除user数据
 * sessionStorage.clearAll() // 清除所有数据
 * sessionStorage.set('user', { name:'Marcus' }, Date.now() + 86400) // 设置user数据，并设置过期时间为1天
 * sessionStorage.getExpiration('user') // 获取user数据的过期时间戳
 */
export const sessionStorage = sessionStore

/**
 * 使用示例：
 * cookieStorage.set('user', { name:'Marcus' }) // 设置user数据
 * cookieStorage.get('user') // 获取user数据
 * cookieStorage.get('user', {}) // 获取user数据，使用{}兜底
 * cookieStorage.remove('user') // 移除user数据
 * cookieStorage.clearAll() // 清除所有数据
 * cookieStorage.set('user', { name:'Marcus' }, Date.now() + 86400) // 设置user数据，并设置过期时间为1天
 * cookieStorage.getExpiration('user') // 获取user数据的过期时间戳
 */
export const cookieStorage = cookieStore
