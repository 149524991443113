import moment from 'moment'
import store from '@/store'
import { LANGUAGE_TYPE } from '@/constants/common'
/**
 * 获取时间格式（根据当前语言编码）
 * @returns 当前语言的时间格式
 */
export const getDateTimeFormat = () => {
  const language = store.state.language
  if (language === LANGUAGE_TYPE.ENGLISH) {
    return 'DD/MM/YYYY HH:mm' // 尼日利亚时间格式
  }
  if (language === LANGUAGE_TYPE.ARABIC) {
    return 'MM/DD/YYYY HH:mm' // 埃及时间格式
  }
  if (language === LANGUAGE_TYPE.CHINESE) {
    return 'YYYY/MM/DD HH:mm' // 中国时间格式
  }
  return 'MMM DD, YYYY, h:mm A' // 英文时间格式兜底
}

/**
 * 获取日期格式(根据当前语言编码)
 * @returns 当前语言日期格式
 */
export const getDateFormat = () => {
  const language = store.state.language
  if (language === LANGUAGE_TYPE.ENGLISH) {
    return 'DD/MM/YYYY' // 尼日利亚日期格式
  }
  if (language === LANGUAGE_TYPE.ARABIC) {
    return 'MM/DD/YYYY' // 埃及日期格式
  }
  if (language === LANGUAGE_TYPE.CHINESE) {
    return 'YYYY/MM/DD' // 中国日期格式
  }
  return 'MMM DD, YYYY' // 英文日期格式兜底
}

/**
 * 格式化日期
 * @param {Number} timestamp 时间戳
 * @returns 格式化后的日期
 * const result = formatDate(1641219152003)
 * //=> 2022/01/03
 */
export const formatDate = timestamp => {
  if (!timestamp) return '-'
  return moment(timestamp).format(getDateFormat())
}

/**
 * 格式化时间
 * @param {*} timestamp 时间戳
 * @returns 格式化后的时间
 * @example
 * const result = formatTime(1641219152003)
 * //=> 2022/01/03 10:12
 */
export const formatDateTime = timestamp => {
  if (!timestamp) return '-'
  return moment(timestamp).format(getDateTimeFormat())
}
/**
 * 格式化日期 返回不同语言环境下的自定义日期格式
 * @param {*} date 时间, en 语言 , format 格式
 * @returns 格式化后的时间
 * @example
 * const result = formatDateForLocale(1641219152003,'en','YYYY-MM-DD')
 * //=> 2022/01/03 10:12
 */
export const formatDateForLocale = (date, locale = 'en', format = 'YYYY-MM-DD') => {
  if (!date) return
  return moment(date).locale(locale).format(format)
}
