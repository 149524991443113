<template>
  <a-dropdown
    v-model="visible"
    class="admin-header-shop-select"
    :trigger="['click']"
    placement="bottomLeft"
  >
    <div class="admin-header-shop-select__shop-area">
      <img
        v-if="shopInfo.logo"
        v-compress="{ src: shopInfo.logo }"
        class="admin-header-shop-select__shop-logo"
      />
      <span v-else class="admin-header-shop-select__no-logo">
        <a-icon class="admin-header-shop-select__svg-logo" :component="IconDefaultShopLogo" />
      </span>
      <span :class="isScreenXs && 'admin-header-shop-select__name'">{{ shopInfo.shopName }}</span>
      <a-icon :type="visible ? 'caret-up' : 'caret-down'" :style="{ color: '#646566' }" />
    </div>
    <div slot="overlay" class="admin-header-shop-select__shop-list-card">
      <div class="admin-header-shop-select__shop-list">
        <a-row
          v-for="(item, index) in shopList"
          :key="index"
          type="flex"
          align="middle"
          class="admin-header-shop-select__card-item"
          @click.native="selectShop(item)"
        >
          <a-col :span="4">
            <img
              v-if="item.logo"
              v-compress="{ src: item.logo }"
              class="admin-header-shop-select__shop-icon"
            />
            <div v-else class="admin-header-shop-select__shop-icon">
              <a-icon
                class="admin-header-shop-select__svg-logo--large"
                :component="IconDefaultShopLogo"
              />
            </div>
          </a-col>
          <a-col :span="18" class="admin-header-shop-select__shop-name">{{ item.shopName }}</a-col>
          <a-col :span="2">
            <a-icon :type="shopInfo.shopId === item.shopId ? 'check' : 'right'" />
          </a-col>
        </a-row>
      </div>
      <a-divider />
      <a-row
        type="flex"
        align="middle"
        class="admin-header-shop-select__card-item"
        @click.native="addShop"
      >
        <a-col :span="4">
          <div class="admin-header-shop-select__shop-icon admin-header-shop-select__shop-add-icon">
            <a-icon class type="plus" />
          </div>
        </a-col>
        <a-col
          :span="18"
          class="admin-header-shop-select__shop-name admin-header-shop-select__shop-add-text"
        >
          {{ $t('account.shopAdd.addShop') }}
        </a-col>
      </a-row>
    </div>
  </a-dropdown>
</template>

<script>
import { mapState } from 'vuex'
import IconDefaultShopLogo from '@/assets/icons/icon-default-shop-logo.svg'
import store from '@/store'
export default {
  name: 'AdminHeaderShopSelect',
  inject: ['reload'],
  data() {
    return {
      IconDefaultShopLogo,
      visible: false,
    }
  },
  computed: {
    ...mapState(['shopInfo', 'shopList', 'isScreenXs']),
  },
  created() {
    this.getShopList()
  },
  methods: {
    addShop() {
      this.$router.push({ path: '/account/shop-add' })
    },
    selectShop(shopInfo) {
      store.commit('setShopInfo', shopInfo)
      store.commit('setCheckoutShopStatus', true)
      this.reload()
    },
    async getShopList() {
      await this.$store.dispatch('getShopListAndUpdateCurrentShopInfo')
    },
  },
}
</script>

<style lang="less" scoped>
.admin-header-shop-select {
  &__name {
    display: inline-block;
    max-width: 80px;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
  }
  &__shop-list-card {
    width: 240px;
    padding: 6px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.1);
    /deep/ .ant-divider-horizontal {
      margin: 8px 0;
    }
  }
  &__shop-list {
    max-height: 170px;
    overflow-y: scroll;
  }
  &__card-item {
    padding: 10px;
    margin-top: 6px;
    cursor: pointer;
    background-color: #f2f3f5;
    &:hover {
      background-color: #e5f8f1;
    }
  }
  &__svg-logo {
    margin-top: 4px;
    font-size: 20px;
    color: #8991a4;
    &--large {
      margin-top: 8px;
      font-size: 24px;
      color: #8991a4;
    }
  }
  &__shop-area {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__shop-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #e5f8f1;
    text-align: center;
    background-color: #fff;
    border-radius: 6px;
  }
  &__no-logo {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    background-color: #e5f8f1;
    border-radius: 6px;
  }
  &__shop-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 6px;
  }
  &__shop-name {
    padding-left: 16px;
    overflow: hidden;
    font-weight: 500;
    color: #181e2d;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
  }
  &__shop-add-icon {
    color: @primary-color;
    background-color: #e5f8f1;
  }
  &__shop-add-text {
    color: @primary-color;
  }
}
</style>
