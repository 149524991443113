<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <op-debug />
      <router-view v-if="isRouterAlive" />
    </div>
  </a-config-provider>
</template>

<script>
import OpDebug from '@/components/op-debug'
export default {
  name: 'App',
  components: {
    OpDebug,
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  computed: {
    locale() {
      return this.$i18n.getLocaleMessage(this.$store.state.language).antLocale
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
        // 针对切换店铺特殊处理 - 防止在商品评价页中切换店铺造成数据不匹配
        if (this.$store.getters.checkoutShopStatus) {
          this.$nextTick(() => {
            this.$store.commit('setCheckoutShopStatus', false)
          })
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
#app {
  height: 100%;
}
</style>
