<template>
  <div class="home-footer">
    <div class="home-footer__container">
      <div class="home-footer__container__card">
        <div class="home-footer__container__card__other">
          <op-site-logo
            height="50"
            :is-light="true"
          />
          <div class="home-footer__container__card__other__copyright">Copyright © 2022 Pandova Inc.</div>
        </div>
        <div class="home-footer__container__card__friend-link">
          <div class="home-footer__container__card__friend-link__item">
            <div
              class="home-footer__container__card__friend-link__item__title"
            >{{ $t('portal.commonFooter.productAndService') }}</div>
            <div class="home-footer__container__card__friend-link__item__link-list">
              <div
                class="home-footer__container__card__friend-link__item__link-list__item cursor-pointer"
                @click="goLink('/portal/app-download')"
              >
                <div>{{ $t('portal.commonFooter.productAndServiceAppDown') }}</div>
              </div>
              <div
                class="home-footer__container__card__friend-link__item__link-list__item cursor-pointer"
                @click="goLink('/portal/help-center')"
              >
                <div>{{ $t('portal.commonFooter.productAndServiceHelpCenter') }}</div>
              </div>
            </div>
          </div>
          <div
            class="home-footer__container__card__friend-link__item home-footer__container__card__friend-link__item__about"
          >
            <div
              class="home-footer__container__card__friend-link__item__title"
            >{{ $t('portal.commonFooter.about') }}</div>
            <div class="home-footer__container__card__friend-link__item__link-list">
              <div
                class="home-footer__container__card__friend-link__item__link-list__item cursor-pointer"
                @click="goLink(serviceTermsLink)"
              >
                <div>{{ $t('portal.commonFooter.aboutServiceTerms') }}</div>
              </div>
              <div
                class="home-footer__container__card__friend-link__item__link-list__item cursor-pointer"
                @click="goLink(privacyPolicyLink)"
              >
                <div>{{ $t('portal.commonFooter.aboutPrivacyPolicy') }}</div>
              </div>
            </div>
          </div>
          <div class="home-footer__container__card__friend-link__item">
            <div
              class="home-footer__container__card__friend-link__item__title"
            >{{ $t('portal.commonFooter.aboutContactUs') }}</div>
            <div class="home-footer__container__card__friend-link__item__link-list">
              <div v-if="countryIsNG">
                <div class="home-footer__container__card__friend-link__item__link-list__item">
                  <div>{{ $t('portal.commonFooter.aboutPhone') }} +23418887531</div>
                </div>
                <div class="home-footer__container__card__friend-link__item__link-list__item">
                  <div>
                    {{ $t('portal.commonFooter.aboutAddress') }}Plot 9, Dr. Nurudeen Olowopopo
                    Avenue,
                    <p>Central Business District, Ikeja, Lagos</p>
                  </div>
                </div>
              </div>
              <div v-if="countryIsEG">
                <div class="home-footer__container__card__friend-link__item__link-list__item">
                  <div>{{ $t('portal.commonFooter.aboutPhone') }} +20 1129287169</div>
                </div>
                <div class="home-footer__container__card__friend-link__item__link-list__item">
                  <div>
                    {{ $t('portal.commonFooter.aboutAddress') }}
                    {{ $t('portal.commonFooter.aboutAddressContent') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-footer__container__card-link">
        <a-icon
          class="home-footer__container__card-link__icon"
          :component="IconTwitter"
          @click="openLink(contactData.twitter)"
        />
        <a :href="contactData.mail">
          <a-icon
            class="home-footer__container__card-link__icon"
            :component="IconEmail"
          />
        </a>

        <a-icon
          class="home-footer__container__card-link__icon"
          :component="IconFacebook"
          @click="openLink(contactData.facebook)"
        />
        <a-icon
          class="home-footer__container__card-link__icon"
          :component="IconInstagram"
          @click="openLink(contactData.instagram)"
        />
        <a-icon
          class="home-footer__container__card-link__icon"
          :component="IconPortalFooterYoutube"
          @click="openLink(contactData.youtube)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconEmail from '@/assets/icons/icon-email.svg'
import IconFacebook from '@/assets/icons/icon-facebook.svg'
import IconInstagram from '@/assets/icons/icon-instagram.svg'
import IconTwitter from '@/assets/icons/icon-twitter.svg'
import IconPortalFooterYoutube from '@/assets/icons/icon-portal-footer-youtube.svg'
import { mapState } from 'vuex'
import { NATION_TYPE } from '@/constants/common'
const contactMap = {
  NG: {
    twitter: 'https://twitter.com/pandovanigeria',
    mail: 'mailto:supportng@opay-inc.com',
    facebook: 'https://www.facebook.com/pandovanigeria',
    instagram: 'https://www.instagram.com/pandovanigeria',
    youtube: 'https://youtube.com/channel/UCmWALlI1_FYh2UDrnlx0jKw',
  },
  EG: {
    twitter: 'https://twitter.com/Pandova5',
    mail: 'mailto:help@mypandova.shop',
    facebook: 'https://www.facebook.com/Pandova-109040461744470',
    instagram: 'https://www.instagram.com/pandova_egypt/',
    youtube: 'https://youtube.com/channel/UCmWALlI1_FYh2UDrnlx0jKw',
  },
}
export default {
  name: 'HomeFooter',
  components: {},
  data() {
    return {
      IconEmail,
      IconFacebook,
      IconInstagram,
      IconTwitter,
      IconPortalFooterYoutube,
    }
  },
  computed: {
    ...mapState(['language', 'countryCode']),
    serviceTermsLink() {
      return `/portal/service-terms?languageCode=${this.language}`
    },
    privacyPolicyLink() {
      return `/portal/privacy-policy?languageCode=${this.language}`
    },
    countryIsNG() {
      return this.countryCode === NATION_TYPE.NIGERIA
    },
    countryIsEG() {
      return this.countryCode === NATION_TYPE.EGYPT
    },
    contactData() {
      return this.countryCode === NATION_TYPE.NIGERIA ? contactMap.NG : contactMap.EG
    },
  },
  methods: {
    goLink(path) {
      this.$router.push({ path })
    },
    openLink(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="less" scoped>
.home-footer {
  padding: 100px 0;
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 184, 118, 1);
  &__container {
    width: 100%;
    max-width: 1440px;
    padding: 0 100px;
    margin: 0 auto;
    &__card {
      display: flex;
      justify-content: space-between;

      &__other {
        display: flex;
        flex-direction: column;
        font-family: 'Karla';
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        &__copyright {
          margin-top: 14px;
        }
      }

      &__friend-link {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        &__item {
          margin: 5px 20px;
          &__title {
            margin-bottom: 9px;
            font-size: 21px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
          }
          &__link-list {
            line-height: 26px;
            &__item.cursor-pointer {
              font-size: 15px;
              cursor: pointer;
            }
          }
        }
        &__item__about {
          margin: 5px 60px;
        }
      }
    }
    &__card-link {
      margin-top: 56px;
      &__icon {
        margin-right: 13px;
        font-size: 40px;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  /deep/ .site-logo {
    img {
      height: 46px;
    }
  }
  .home-footer {
    padding: 24px 10px;
    &__container {
      padding: 0;
      &__card {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        &__other {
          display: flex;
          justify-content: center;
          margin-left: 20px;

          &__logo {
            width: 110px;
          }
          &__copyright {
            margin-top: 3px;
            font-size: 14px;
            white-space: nowrap;
          }
        }
        &__friend-link {
          &__item {
            margin: 15px 17px;
            &__title {
              margin-bottom: 2px;
              font-size: 16px;
            }
          }
        }
      }
      &__card-link {
        display: flex;
        margin-top: 18px;
        margin-bottom: 10px;
        margin-left: 24px;
        text-align: center;
      }
    }
  }
}
</style>
