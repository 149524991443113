<template>
  <a-menu
    :theme="theme"
    :mode="mode"
    :inline-collapsed="collapsed"
    :open-keys="openKeys"
    :selected-keys="selectedKeys"
    @select="onSelectedKeys"
    @openChange="handleOpenChange"
  >
    <template v-for="item in menus">
      <a-sub-menu
        v-if="item.children && !item.hideChildrenInMenu"
        :key="item.path"
      >
        <span
          slot="title"
          class="custom-menu-module"
        >
          <a-icon :component="item.meta.icon" />
          <span>{{ item.meta.title }}</span>
          <a-icon
            v-if="item.meta.markIcon"
            :component="item.meta.markIcon"
            class="icon-mark"
            @click="handleMenuMarkIconClick(item)"
          />
        </span>
        <a-menu-item
          v-for="child in item.children"
          v-show="!item.hideChildrenInMenu && !child.hidden"
          :key="child.path"
        >
          <span>{{ child.meta.title }}</span>
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item
        v-else
        :key="item.path"
      >
        <a-icon :component="item.meta.icon" />
        <span>{{ item.meta.title }}</span>
      </a-menu-item>
    </template>
  </a-menu>
</template>
<script>
const httpReg = /(http|https|ftp):\/\/([\w.]+\/?)\S*/

export default {
  name: 'BaseMenu',
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    menus: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'inline',
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      openKeys: [],
      selectedKeys: [],
      cachedOpenKeys: [],
      cachedSelectedKeys: [],
    }
  },
  computed: {},
  created() {
    this.$watch('$route', () => {
      this.updateMenu()
    })
    this.$watch('collapsed', val => {
      if (val) {
        this.cachedOpenKeys = this.openKeys.concat()
        this.openKeys = []
      } else {
        this.openKeys = this.cachedOpenKeys
      }
    })
  },
  mounted() {
    this.updateMenu()
  },
  methods: {
    rootSubmenuKeys(vm) {
      const keys = []
      this.menus.forEach(item => keys.push(item.path))
      return keys
    },
    handleOpenChange(openKeys) {
      // 在水平模式下时，不再执行后续
      if (this.mode === 'horizontal') {
        this.openKeys = openKeys
        return
      }
      const latestOpenKey = openKeys.find(key => !this.openKeys.includes(key))
      if (!this.rootSubmenuKeys().includes(latestOpenKey)) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    onSelectedKeys(menu) {
      this.$router.push({
        path: menu.key,
      })
      if (!httpReg.test(menu.key)) {
        this.selectedKeys = menu.selectedKeys
      }
      this.$emit('selectChange', menu)
    },
    updateMenu() {
      const routes = this.$route.matched.concat()
      const { showParentPath, parentPath } = this.$route.meta
      this.selectedKeys = [routes[1].path]
      if (routes.length >= 3 && !showParentPath) {
        this.selectedKeys = [routes[2].path]
      }
      if (parentPath) {
        this.selectedKeys = [parentPath]
      }
      const openKeys = []
      if (this.mode === 'inline') {
        routes.forEach(item => {
          item.path && openKeys.push(item.path)
        })
      }
      this.collapsed ? (this.cachedOpenKeys = openKeys) : (this.openKeys = openKeys)
    },
    handleMenuMarkIconClick(item) {
      item.meta.markIconEvent?.()
    },
  },
}
</script>
<style lang="less" scoped>
.custom-menu-module {
  display: flex;
  align-items: center;
  .icon-mark {
    margin-left: auto;
  }
}
</style>
