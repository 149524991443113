import { set, cloneDeep } from 'lodash'
import { createRecord, postMessage } from '@/views/online-shop/shop-design/config'
export default {
  namespaced: true,
  state: {
    currentTheme: {}, // 当前主题数据
    themeLibrarySchema: {}, // 全量schema对象
    sectionPanelData: {}, // 全量装修配置数据
    currentActiveComponentKeys: [], // 当前选中的组件层级
    currentActiveDataKeys: [], // 当前选中的数据层级
    currentActiveKey: '', // 当前选中组件id
    currentActiveComponentType: '', // 当前选中组件类型（组件，全局配置）
    draggableState: false, // 当前是否处于拖拽状态
    sideLeftShow: true, // 店铺装修左侧组件选择是否展示
    sideRightShow: false, // 店铺装修左侧属性编辑是否展示
    historyData: {
      // 修改历史记录
      undoList: [],
      rollbackList: [],
    },
    record: createRecord(),
    productCategoryList: [],
    productList: [],
  },
  getters: {
    flattenThemeSchema(state) {
      const data = {}
      const sections = cloneDeep(state.themeLibrarySchema.sections) || []
      sections.forEach(section => {
        const blockData = {}
        section.blockSettings = section.blocks
        ;(section.blocks || []).forEach(block => {
          blockData[block.type] = block
        })
        section.blocks = blockData
        data[section.type] = section
      })
      return data
    },
    flattenThemeGlobalSchema(state) {
      const data = {}
      const global = cloneDeep(state.themeLibrarySchema.global) || []
      global.forEach(item => {
        data[item.type] = item
      })
      return data
    },
    sectionPanelData(state) {
      return state.sectionPanelData
    },
  },
  mutations: {
    setHistoryData: (state, data) => {
      const newValue = {
        sectionPanelData: data || state.sectionPanelData,
        currentActiveComponentKeys: state.currentActiveComponentKeys,
        currentActiveDataKeys: state.currentActiveDataKeys,
        currentActiveKey: state.currentActiveKey,
        currentActiveComponentType: state.currentActiveComponentType,
      }
      state.record.addRecord(cloneDeep(newValue))
      state.historyData.undoList = state.record.getUndoStack()
      state.historyData.rollbackList = state.record.getrollbackStack()
    },
    emptyHistoryData: (state, data) => {
      state.record = createRecord()
      state.historyData = {
        undoList: [],
        rollbackList: [],
      }
    },
    setHistoryDataCurrent: (state, type) => {
      type === 'back' ? state.record.rollbackRecord() : state.record.undoRecord()
      state.historyData.undoList = state.record.getUndoStack()
      state.historyData.rollbackList = state.record.getrollbackStack()
      state.sectionPanelData = cloneDeep(state.record.getTopValue()).sectionPanelData
      postMessage({
        type: 'update',
        activeSectionId: state.currentActiveDataKeys[0],
        themeData: state.sectionPanelData,
      })
      if (state.currentActiveComponentType === 'global') {
        return
      }

      state.currentActiveComponentType = cloneDeep(
        state.record.getTopValue()
      ).currentActiveComponentType
      state.currentActiveComponentKeys = cloneDeep(
        state.record.getTopValue()
      ).currentActiveComponentKeys
      state.currentActiveDataKeys = cloneDeep(state.record.getTopValue()).currentActiveDataKeys
      state.currentActiveKey = cloneDeep(state.record.getTopValue()).currentActiveKey
      state.sideRightShow = !!state.currentActiveKey
      state.sideLeftShow = document.body.scrollWidth > 1600 || !state.currentActiveKey
    },
    setDraggableState: (state, data) => {
      state.draggableState = data
    },
    setSideLeftShow: (state, data) => {
      state.sideLeftShow = data
    },
    setSideRightShow: (state, data) => {
      state.sideRightShow = data
    },
    setCurrentActiveComponentType: (state, data) => {
      state.currentActiveComponentType = data
    },
    setCurrentActiveKey: (state, data) => {
      state.currentActiveKey = data
    },
    setThemeLibrarySchema: (state, data) => {
      state.themeLibrarySchema = data
    },
    setSectionPanelData: (state, data, key) => {
      state.sectionPanelData = data
    },
    setSectionPanelDataComponents: (state, data) => {
      const panelData = cloneDeep(state.sectionPanelData)
      const { path, value } = data || {}
      set(panelData, path, value)
      state.sectionPanelData = panelData
      console.info('state.sectionPanelData ===>', state.sectionPanelData)
    },
    setCurrentActiveComponentKeys: (state, data) => {
      state.currentActiveComponentKeys = data
    },
    setCurrentActiveDataKeys: (state, data) => {
      state.currentActiveDataKeys = data
    },
    setCurrentTheme(state, data) {
      state.currentTheme = data
    },
    setProductCategoryList(state, data) {
      state.productCategoryList = data
    },
    setProductList(state, data) {
      state.productList = data
    },
  },
}
