<template>
  <a-spin :spinning="spinning">
    <ul
      class="notification-area"
      @scroll="scrollEvent"
    >
      <li
        v-for="(item, index) in messageList"
        :key="item.id"
        class="notification-area__item"
        @click="goOrderDetail(item, index)"
      >
        <div
          class="notification-area__item__title"
          :class="
            item.isRead === NOTIFICATION_STATUS.READ
              ? 'notification-area__item__read'
              : 'notification-area__item__no-read'
          "
        >{{ item.title }}</div>
        <div class="notification-area__item__description">{{ item.content }}</div>
        <div class="notification-area__item__date">{{ item.sendTime | formatDateTime }}</div>
      </li>
      <div
        v-if="isAll"
        class="notification-area__is-all"
      >没有更多消息了.....</div>
      <a-empty
        v-if="!total"
        class="notification-area__empty-icon"
        :description="false"
      />
    </ul>
  </a-spin>
</template>
<script>
import { mapState } from 'vuex'
import { getMessageList, reportMessageRead } from '@/api/setting'
import { NOTIFICATION_STATUS } from '@/constants/setting'
import { openWindow, formatDateTime } from '@/utils'
import { LANGUAGE_COUNTRY_ID } from '@/constants/common'
export default {
  name: 'NotificationDrawer',
  filters: {
    formatDateTime,
  },
  data() {
    return {
      NOTIFICATION_STATUS,
      messageList: [],
      size: 20,
      start: 0,
      spinning: false,
      total: 0,
    }
  },
  computed: {
    ...mapState(['badgeNumber', 'shopInfo', 'language']),
    isAll() {
      return this.total && this.total <= this.start
    },
  },
  mounted() {
    this.getMessageList()
  },
  methods: {
    scrollEvent(e) {
      if (this.isAll) {
        return
      }
      if (e.srcElement.scrollTop + e.srcElement.clientHeight === e.srcElement.scrollHeight) {
        this.getMessageList()
      }
    },
    async goOrderDetail(item, index) {
      openWindow(item.url)
      if (item.isRead === NOTIFICATION_STATUS.UNREAD) {
        await reportMessageRead({ messageId: item.id })
        this.messageList[index].isRead = NOTIFICATION_STATUS.READ
        this.$store.commit('setBadgeNumber', --this.badgeNumber || 0)
      }
    },
    async getMessageList() {
      try {
        this.spinning = true
        const { data, totalRecords } = await getMessageList({
          countryLngType: LANGUAGE_COUNTRY_ID[`${this.shopInfo.country}-${this.language}`],
          size: this.size,
          start: this.start,
        })
        this.total = totalRecords
        this.messageList = this.messageList.concat(data || [])
        this.start = this.start + this.size
      } catch (error) {
      } finally {
        this.spinning = false
      }
    },
  },
}
</script>
<style lang="less" scoped>
.notification-area {
  height: 100%;
  min-height: 600px;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  &__item {
    padding: 24px 0;
    margin: 0 24px;
    list-style: none;
    cursor: pointer;
    border-bottom: 1px solid #dedede;
    &__title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 600;
    }
    &__description {
      margin-bottom: 12px;
      color: #999;
    }
    &__date {
      color: #999;
    }
    &__read {
      color: #999;
    }
    &__no-read {
      margin-left: -18px;
    }
    &__no-read::before {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 8px;
      content: ' ';
      background: @primary-color;
      border-radius: 5px;
    }
  }
  &__is-all {
    padding: 12px;
    color: #b8b8b8;
    text-align: center;
  }
  &__empty-icon {
    margin-top: 45%;
    text-align: center;
  }
}
.ant-spin-nested-loading {
  height: 100%;
}
/deep/ .ant-spin-container {
  height: 100%;
}
</style>
<style lang="less">
.ant-drawer-body {
  padding-top: 0 !important;
}
</style>
