import { request, METHOD, isDevelopment } from '@/utils'
// 编辑店铺信息
export const editShopInfo = params => {
  return request('/saas-api/account/v1/shop-info/web-edit', METHOD.POST, params)
}
// 获取当前登录的店铺详情
export const getShopDetail = params => {
  return request('/saas-api/account/v1/shop-info/detail', METHOD.GET, params)
}

// 获取行业下拉数据
export const getIndustryList = params => {
  return request('/saas-api/bff/unAuth/v1/dict/INDUSTRY/item', METHOD.GET, params)
}
// 获取语言下拉数据
export const getLanguageList = params => {
  return request('/saas-api/bff/unAuth/v1/dict/LANGUAGE/item', METHOD.GET, params)
}
// 获取国家货币、国际拨号等信息
export const getCurrency = params => {
  return request(`/saas-api/bff/unAuth/v1/country/${params.countryId}`, METHOD.GET)
}

// 编辑账户设置
export const editAccountInfo = params => {
  return request('/saas-api/account/v1/shop-user/edit-personal', METHOD.POST, params)
}
// 获取当前登录人信息
export const getUserInfo = params => {
  return request('/saas-api/account/v1/shop-user/login-user-info', METHOD.GET, params)
}
// 商家后台和商家APP获取所有未删除的运输方式
export const getLogisticsList = params => {
  return request('/saas-api/config/v1/shipping/list', METHOD.GET, params)
}
// 获取运输方式详情
export const getLogisticsDetail = params => {
  return request(`/saas-api/config/v1/shipping/detail/${params.id}`, METHOD.GET)
}
// 删除运输方式
export const deleteLogistics = params => {
  return request(`/saas-api/config/v1/shipping/delete/${params.id}`, METHOD.GET)
}
// 修改运输方式
export const editLogistics = params => {
  return request('/saas-api/config/v1/shipping/update', METHOD.POST, params)
}
// 新增运输方式
export const addLogistics = params => {
  return request('/saas-api/config/v1/shipping/save', METHOD.POST, params)
}

// 获取税率列表
export const getTaxList = params => {
  return request('/saas-api/config/api/v1/tax/rate/find/all', METHOD.POST, params)
}
// 新增税率
export const addTax = params => {
  return request('/saas-api/config/api/v1/tax/rate/save', METHOD.POST, params)
}
// 删除税率
export const deleteTax = params => {
  return request('/saas-api/config/api/v1/tax/rate/delete', METHOD.POST, params)
}
// 修改税率
export const editTax = params => {
  return request('/saas-api/config/api/v1/tax/rate/update', METHOD.POST, params)
}
// 查看税率详情
export const getTaxDetail = params => {
  return request('/saas-api/config/api/v1/tax/rate/find', METHOD.POST, params)
}

// 获取员工列表
export const getStaffList = params => {
  return request('/saas-api/account/v1/shop-user/staff-page-list', METHOD.POST, params)
}
// 添加员工发送邀请
export const sendStaffInvitation = params => {
  return request('/saas-api/account/v1/shop-user/send-staff-invitation', METHOD.POST, params)
}
// 删除员工
export const deleteStaff = params => {
  return request('/saas-api/account/v1/shop-user/staff-delete', METHOD.POST, params)
}

// 查询消息列表
export const getMessageList = params => {
  return request('/saas-api/message/v1/message/list', METHOD.POST, params)
}
// 查询未读消息数量
export const getMessageUnreadNum = params => {
  return request(
    `/saas-api/message/v1/message/query-unread-num/${params.countryLngType}`,
    METHOD.GET
  )
}
// 消息已读上报
export const reportMessageRead = params => {
  return request('/saas-api/message/v1/message/read', METHOD.GET, params)
}

// 收款状态查询（绑定OPay支付账号）
// export const getReceiptStatus = params => {
//   return request('/saas-api/account/v1/shop-info/receipt-status', METHOD.GET, params)
// }
// payment状态查询
export const getMerchantStatus = params => {
  // return request('/saas-api/account/v1/merchant/status', METHOD.POST, params)
  return request('/saas-api/bff/v1/payment/merchant/status', METHOD.POST, params)
}
// payment状态查询 （后端返回所需数据，前端纯展示）
export const getNewMerchantStatus = params => {
  // return request('/saas-api/bff/v1/merchant/status', METHOD.POST, params)
  return request('/saas-api/bff/v1/payment/merchant/info', METHOD.POST, params)
}
// 商家修改密码
export const updatePassword = params => {
  return request('/saas-api/account/v1/shop-user/edit-password', METHOD.POST, params)
}

// 快速开通接口
export const fastOpenPayment = params => {
  // return request('/saas-api/account/v1/merchant/fast-open-payment', METHOD.POST, params)
  return request('/saas-api/bff/v1/merchant/fast-open-payment', METHOD.POST, params)
}
// 埃及kyc提交接口
export const submitEgKycInfo = params => {
  // return request('/saas-api/account/v1/merchant/submit-eg-kyc-info', METHOD.POST, params)
  return request('/saas-api/bff/v1/merchant/submit-eg-kyc-info', METHOD.POST, params)
}
// 开通失败埃及kyc提交记录(编辑回显)
export const getEgKycInfo = params => {
  // return request('/saas-api/account/v1/merchant/get-eg-kyc-info', METHOD.POST, params)
  return request('/saas-api/payment/v1/merchant/get-eg-kyc-info', METHOD.POST, params)
}
// 开通失败尼日kyc提交记录(编辑回显)
export const getNgKycInfo = params => {
  // return request('/saas-api/account/v1/merchant/get-ng-kyc-info', METHOD.POST, params)
  return request('/saas-api/payment/v1/merchant/get-ng-kyc-info', METHOD.POST, params)
}
// 获取银行列表
export const getBankList = params => {
  // return request(`/saas-api/account/v1/merchant/banks/${params.country}`, METHOD.GET)
  return request(`/saas-api/payment/v1/banks/${params.country}`, METHOD.GET)
}
// 获取行业列表
export const getIndustriesList = params => {
  // return request(`/saas-api/account/v1/merchant/industries/${params.country}`, METHOD.GET)
  return request(`/saas-api/payment/v1/industries/${params.country}`, METHOD.GET)
}
// 获取省/市列表
export const getStateAndCityList = params => {
  // return request(`/saas-api/account/v1/merchant/areas/${params.country}`, METHOD.GET)
  return request(`/saas-api/payment/v1/areas/${params.country}`, METHOD.GET)
}
// 上传文件
export const uploadFile = params => {
  return request('/saas-api/bff/v1/file/repeat-upload-file', METHOD.POST, params)
}
// 获取银行账户名称
export const getBankAccountName = params => {
  // return request('/saas-api/account/v1/merchant/query-bank-account-name', METHOD.POST, params)
  return request('/saas-api/bff/v1/payment/query-bank-account-name', METHOD.POST, params)
}
// 校验bvn
export const verifyBvn = params => {
  // return request('/saas-api/account/v1/merchant/verify-bvn', METHOD.POST, params)
  return request('/saas-api/bff/v1/merchant/verify-bvn', METHOD.POST, params)
}
// 获取银行账户名称 （尼日）
export const getBankAccountNameNg = params => {
  // return request('/saas-api/account/v2/merchant/query-bank-account-name', METHOD.POST, params)
  return request('/saas-api/bff/v2/merchant/query-bank-account-name', METHOD.POST, params)
}
// 校验bvn （尼日）
export const verifyBvnNg = params => {
  // return request('/saas-api/account/v2/merchant/verify-bvn', METHOD.POST, params)
  return request('/saas-api/bff/v2/merchant/verify-bvn', METHOD.POST, params)
}
// 获取店铺域名列表
export const getShopDomainList = params => {
  return isDevelopment()
    ? request('/mock-api/account/v1/shop-domain/list', METHOD.POST, params)
    : request('/saas-api/account/v1/shop-domain/list', METHOD.POST, params)
}

// 设置主域名
export const setMainDomain = params => {
  return request('/saas-api/account/v1/shop-domain/set-master', METHOD.POST, params)
}

// 删除域名
export const deleteDomain = params => {
  return request('/saas-api/account/v1/shop-domain/del', METHOD.POST, params)
}

// 绑定域名
export const bindDomain = params => {
  return request('/saas-api/account/v1/shop-domain/bind', METHOD.POST, params)
}
// 查询运费方案下可选的省市
export const getStateAndCity = params => {
  return request('/saas-api/config/v1/shipping/schema/query/city', METHOD.GET, params)
}

// 查询运费方案
export const getShippingInfo = params => {
  return request('/saas-api/config/v1/shipping/schema/query', METHOD.GET, params)
}
// 创建或编辑运费方案
export const setShippingInfo = params => {
  return request('/saas-api/config/v1/shipping/schema/edit', METHOD.POST, params)
}
// 类目列表查询
export const getChargeCategoryList = params => {
  return request('/saas-api/shop-order/v1/expense/category/list', METHOD.POST, params)
}

// 添加类目
export const addChargeCategory = params => {
  return request('/saas-api/shop-order/v1/expense/category/save', METHOD.POST, params)
}

// 编辑类目
export const updateChargeCategory = params => {
  return request('/saas-api/shop-order/v1/expense/category/edit', METHOD.POST, params)
}

// 移除类目
export const deleteChargeCategory = params => {
  return request(`/saas-api/shop-order/v1/expense/category/remove/${params.id}`, METHOD.GET)
}

// 记账记录列表查询
export const getRecordList = params => {
  return request('/saas-api/shop-order/v1/expense/record/list', METHOD.POST, params)
}

// 记账记录详情
export const getRecordDetail = params => {
  return request(`/saas-api/shop-order/v1/expense/record/get/${params.id}`, METHOD.GET)
}

// 添加记账记录
export const addRecord = params => {
  return request('/saas-api/shop-order/v1/expense/record/save', METHOD.POST, params)
}

// 编辑记账记录
export const updateRecord = params => {
  return request('/saas-api/shop-order/v1/expense/record/edit', METHOD.POST, params)
}

// 移除记账记录
export const deleteRecord = params => {
  return request(`/saas-api/shop-order/v1/expense/record/remove/${params.id}`, METHOD.GET)
}

// 查询所有的类目(不分页)
export const getAllChargeCategoryList = params => {
  return request('/saas-api/shop-order/v1/expense/category/all', METHOD.GET, params)
}

// 获取列表
export const getSmsSendList = params => {
  return request('/saas-api/message/sms/api/v1/sms/send/census', METHOD.POST, params)
}

// 查看短信余量
export const getSmsResidueCount = params => {
  return request('/saas-api/message/sms/api/v1/sms/view/quantity', METHOD.POST, params)
}

// 短信充值统计列表
export const getSmsStatisticsCountList = params => {
  return request('/saas-api/message/sms/api/v1/sms/recharge/census', METHOD.POST, params)
}

// 套餐列表
export const getSmsCombo = params => {
  return request('/saas-api/message/sms/api/v1/combo/find/all', METHOD.POST, params)
}

// 短信预购买 给前端返回收银台地址
export const buySmsCombo = params => {
  return request('/saas-api/message/sms/api/v1/sms/pre/buy', METHOD.POST, params)
}
// 短信购买完成后回调
export const smsCallBack = params => {
  return request('/saas-api/message/sms/api/v1/sms/view/status', METHOD.POST, params)
}

// 查询尼日kyc信息V2接口
export const getNgKycDetail = params => {
  // return request('/saas-api/account/v2/merchant/get-ng-kyc-info', METHOD.GET, params)
  return request('/saas-api/bff/v2/merchant/get-ng-kyc-info', METHOD.GET, params)
}
// 尼日kyc提交v2接口
export const submitNgKycInfo = params => {
  // return request('/saas-api/account/v2/merchant/submit-ng-kyc-info', METHOD.POST, params)
  return request('/saas-api/bff/v2/merchant/submit-ng-kyc-info', METHOD.POST, params)
}

// 获取尼日payment信息（卡icon、开通状态、收款表格）
export const getNgPaymentInfo = params => {
  // return request('/saas-api/account/v1/merchant/get-ng-payment-info', METHOD.GET, params)
  return request('/saas-api/bff/v1/merchant/get-ng-payment-info', METHOD.GET, params)
}
// 开关COD开关
export const openOrCloseCod = params => {
  // return request('/saas-api/account/v1/merchant/switch-cod', METHOD.GET, params)
  return request('/saas-api/bff/v1/payment/switch-cod', METHOD.GET, params)
}

// 查询COD状态
export const checkCodStatus = params => {
  // return request('/saas-api/account/v1/merchant/pay-method', METHOD.GET, params)
  return request('/saas-api/payment/v1/merchant/pay-method', METHOD.GET, params)
}
