import { hasPermission } from '@/utils'

/**
 * 权限指令，可以隐藏元素，示例：
 * <your-component
 *  v-permission="{sign: 'permissionSign'}"
 * />
 */
export default {
  name: 'permission',
  inserted(el, binding, vnode) {
    const { sign } = binding.value || {}
    if (sign && !hasPermission(sign)) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  },
}
