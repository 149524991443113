// 默认语言
export const DEFAULT_LANGUAGE = 'en-US'

// 渠道推广码失效天数
export const MAX_LIVE_DURATION = 30
// 中文编码
export const CHINESE = 'zh-CN'

// 语言类型
export const LANGUAGE_TYPE = {
  ENGLISH: 'en-US',
  ARABIC: 'ar-EG',
}

// 语言类型文案映射
export const LANGUAGE_TYPE_TEXT_MAP = {
  [LANGUAGE_TYPE.ENGLISH]: 'English',
  [LANGUAGE_TYPE.ARABIC]: 'العربية',
}

if (navigator.language === CHINESE) {
  LANGUAGE_TYPE.CHINESE = CHINESE
  LANGUAGE_TYPE_TEXT_MAP[CHINESE] = '简体中文'
}
// 语言类型文案映射列表
export const LANGUAGE_TYPE_TEXT_MAP_LIST = Object.values(LANGUAGE_TYPE).map(item => {
  return {
    label: LANGUAGE_TYPE_TEXT_MAP[item],
    value: item,
  }
})

// 语言对应国家类型
export const LANGUAGE_COUNTRY_TYPE = {
  NG_ENGLISH: 'NG-en-US',
  EG_ENGLISH: 'EG-en-US',
  EG_ARABIC: 'EG-ar-EG',
}
export const LANGUAGE_COUNTRY_FLAG = {
  [LANGUAGE_COUNTRY_TYPE.NG_ENGLISH]:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAASCAYAAACq26WdAAAAAXNSR0IArs4c6QAAAJNJREFUSEtjZJqc9JwBBP4z/AfT6ICR4f+XlCmMjAyM2OUZGBhefP/EpLy08h9W/QjBH4xMk5Pe/GNhEsan8H/GbLzmPP/28bXUohJRApZdHrVsNBjxhcBoAhmS+WxK8ut/zIwi9ClBRi3DE87EF8R0DsZX/5gZ8VYPVKtiGKckv/rPzMhMYWr8K7WoBK8ZDAwMTwEUksWEGyHyTwAAAABJRU5ErkJggg==',
  [LANGUAGE_COUNTRY_TYPE.EG_ENGLISH]:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAASCAYAAACq26WdAAAAAXNSR0IArs4c6QAAAOFJREFUSEvtlD0KwkAQhd9sFBTxh4Ai2Kh4gBwgtUfwAB7As3gOsResbQRPYKNFJFUgxOBuNMlIDiBsEpLKqefN93gzDJ0tO0DFxQDFhCddLDsE0KmYhzfg/GGlU64/xjSKsgMx8lhPHsuWMTmqPBolpUfMnO8aEx8cu4Bog8QAMAZaTKWUkxuWBnuk3haiv4IwN1qgrKkQLBPGNxuN2Qn8uYOaUy1gYVga7CB6KyDxC8XY1rIHgKMrcXiAMNcM6urKIKV0aT4ehlTDuyIi5w/T3s2vxvpjXIxHXmnbOgMIry/lcXZ3eDk3twAAAABJRU5ErkJggg==',
  [LANGUAGE_COUNTRY_TYPE.EG_ARABIC]:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAASCAYAAACq26WdAAAAAXNSR0IArs4c6QAAAOFJREFUSEvtlD0KwkAQhd9sFBTxh4Ai2Kh4gBwgtUfwAB7As3gOsResbQRPYKNFJFUgxOBuNMlIDiBsEpLKqefN93gzDJ0tO0DFxQDFhCddLDsE0KmYhzfg/GGlU64/xjSKsgMx8lhPHsuWMTmqPBolpUfMnO8aEx8cu4Bog8QAMAZaTKWUkxuWBnuk3haiv4IwN1qgrKkQLBPGNxuN2Qn8uYOaUy1gYVga7CB6KyDxC8XY1rIHgKMrcXiAMNcM6urKIKV0aT4ehlTDuyIi5w/T3s2vxvpjXIxHXmnbOgMIry/lcXZ3eDk3twAAAABJRU5ErkJggg==',
}
// 语言对应国家code文案映射
export const LANGUAGE_COUNTRY_TYPE_TEXT_MAP = {
  [LANGUAGE_COUNTRY_TYPE.NG_ENGLISH]: 'Nigeria(English)',
  [LANGUAGE_COUNTRY_TYPE.EG_ENGLISH]: 'Egypt(English)',
  [LANGUAGE_COUNTRY_TYPE.EG_ARABIC]: 'مصر(العربية)',
}
if (navigator.language === CHINESE) {
  LANGUAGE_COUNTRY_TYPE.ZH_CHINA = 'NG-zh-CN'
  LANGUAGE_COUNTRY_TYPE_TEXT_MAP[LANGUAGE_COUNTRY_TYPE.ZH_CHINA] = '中国(简体中文)'
  LANGUAGE_COUNTRY_FLAG[LANGUAGE_COUNTRY_TYPE.ZH_CHINA] =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAASCAMAAACdBVWvAAAAPFBMVEXWSyTWSyTPSiTWSyTfbxvbXB/qmhHdZx3vrAzxsgrYUSPiexj2xwX62QHYVSLZWCHhdRrjfxfokhLuqA2v8Z4gAAAAA3RSTlPz7uFaBAXbAAAAV0lEQVQY093LyQ3CQBREQeP6s3hjzT9XEAckPJ4E/KQ+lXoYh34jEuqL2V9fy1BSsbeIWD9znabGpLqtgU1rlvp7NJbFDflxYEjuT+XYWPKsZzinXbq9ATQIBf2OjKeLAAAAAElFTkSuQmCC'
}
// 语言对应国家文案映射列表
export const LANGUAGE_COUNTRY_TYPE_TEXT_MAP_LIST = Object.values(LANGUAGE_COUNTRY_TYPE).map(
  item => {
    return {
      label: LANGUAGE_COUNTRY_TYPE_TEXT_MAP[item],
      value: item,
    }
  }
)
// 语言对应国家类型对应的id
export const LANGUAGE_COUNTRY_ID = {
  'NG-en-US': 1,
  'EG-en-US': 2,
  'EG-ar-EG': 3,
  'NG-zh-CN': 1,
  'EG-zh-CN': 2,
}
// 国家code
export const NATION_TYPE = {
  NIGERIA: 'NG',
  EGYPT: 'EG',
}

// 国家code文案映射
export const NATION_TYPE_TEXT_MAP = {
  [NATION_TYPE.NIGERIA]: '尼日利亚',
  [NATION_TYPE.EGYPT]: '埃及',
}

// 国家code文案映射列表
export const NATION_TYPE_TEXT_MAP_LIST = Object.values(NATION_TYPE).map(item => {
  return {
    label: NATION_TYPE_TEXT_MAP[item],
    value: item,
  }
})
// 是、否
export const JUDGE_TYPE = {
  YES: 1,
  NO: 2,
}

// 是、否文案映射
export const JUDGE_TYPE_TEXT_MAP = {
  [JUDGE_TYPE.YES]: '是',
  [JUDGE_TYPE.NO]: '否',
}

/**
 * 国家-语言 对应 语言
 * 例：NG-en-US : en-US
 */
export const languageMap = {
  [LANGUAGE_COUNTRY_TYPE.NG_ENGLISH]: LANGUAGE_TYPE.ENGLISH,
  [LANGUAGE_COUNTRY_TYPE.EG_ENGLISH]: LANGUAGE_TYPE.ENGLISH,
  [LANGUAGE_COUNTRY_TYPE.EG_ARABIC]: LANGUAGE_TYPE.ARABIC,
  [LANGUAGE_COUNTRY_TYPE.ZH_CHINA]: LANGUAGE_TYPE.CHINESE,
}
/**
 * 国家-语言 对应 国家
 * 例：NG-en-US : NG
 */
export const countryMap = {
  [LANGUAGE_COUNTRY_TYPE.NG_ENGLISH]: NATION_TYPE.NIGERIA,
  [LANGUAGE_COUNTRY_TYPE.EG_ENGLISH]: NATION_TYPE.EGYPT,
  [LANGUAGE_COUNTRY_TYPE.EG_ARABIC]: NATION_TYPE.EGYPT,
  [LANGUAGE_COUNTRY_TYPE.ZH_CHINA]: NATION_TYPE.NIGERIA,
}

// 邮箱校验正则
export const EMAIL_VALIDATE_REG = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
// 密码校验正则
export const PASSWORD_VALIDATE_REG = /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{8,16}$/

export const BANNER_CONFIG_TYPE = {
  WEBSITE: 'official_website',
  ADMIN: 'oerchant_background',
}

export const BANNER_LANG_TYPE = {
  NIGERIA_EN: 1,
  EGYPT_EN: 2,
  EGYPT_AR: 3,
}
