<template>
  <container-query
    :query="screenQuery"
    @change="handleMediaQuery"
  >
    <div
      class="admin-layout"
      :class="isPandovaApp && 'admin-layout--no-header'"
    >
      <customer-service v-if="!isPandovaApp" />
      <a-layout class="admin-layout__trigger">
        <a-drawer
          v-if="isScreenXs"
          :visible="!collapsed"
          placement="left"
          :closable="false"
          :width="menuWidth"
          :body-style="{ padding: '0' }"
          @close="handleCollapse"
        >
          <a-layout-sider
            v-model="collapsed"
            :collapsed-width="isScreenXs ? 0 : 'auto'"
            :trigger="null"
            collapsible
            class="layout-left"
          >
            <div class="admin-layout__trigger__logo">
              <op-site-logo height="30" />
            </div>
            <base-menu
              :collapsed="false"
              mode="inline"
              :menus="menus"
              theme="light"
              class="sider-menus"
              @selectChange="handleCollapse"
            ></base-menu>
          </a-layout-sider>
        </a-drawer>
        <a-layout-sider
          v-show="!isScreenXs"
          v-model="collapsed"
          collapsible
          :trigger="null"
          class="layout-left"
        >
          <div class="admin-layout__trigger__logo">
            <img
              v-show="collapsed"
              v-compress="{
                src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/common/icon-pandova.png',
              }"
              class="admin-layout__trigger__logo-collapsed"
            />
            <op-site-logo
              v-show="!collapsed"
              height="30"
            />
          </div>
          <base-menu
            class="sider-menus"
            :collapsed="collapsed"
            mode="inline"
            :menus="menus"
            theme="light"
          ></base-menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header
            class="layout-header"
            :style="headerWidth"
          >
            <a-row type="flex">
              <a-col
                flex="64px"
                class="trigger-icon-box"
              >
                <a-icon
                  class="trigger-icon"
                  :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                  @click="handleCollapse"
                />
              </a-col>
              <a-col flex="auto">
                <a-row
                  type="flex"
                  justify="space-between"
                  align="middle"
                >
                  <admin-header-shop-select v-if="!isPandovaApp" />
                  <admin-header-right-content v-if="!isPandovaApp" />
                </a-row>
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content class="content">
            <router-view />
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
  </container-query>
</template>

<script>
import { getAdminLayoutRoutes } from '@/router/router.config'
import { isPandovaApp } from '@/utils'
import AdminHeaderRightContent from './components/admin-header-right-content.vue'
import AdminHeaderShopSelect from './components/admin-header-shop-select.vue'
import CustomerService from './components/customer-service.vue'
import BaseMenu from './components/base-menu.vue'
import { ContainerQuery } from 'vue-container-query'
import { getScreenQuery } from './config'

export default {
  name: 'AdminLayout',
  components: {
    AdminHeaderRightContent,
    AdminHeaderShopSelect,
    CustomerService,
    BaseMenu,
    ContainerQuery,
  },
  data() {
    return {
      collapsed: false,
      title: 'Pandova',
      screenQuery: getScreenQuery(),
      isScreenXs: false,
      isPandovaApp: isPandovaApp(),
      menuWidth: '200px',
    }
  },
  computed: {
    menus: () => {
      const routes = getAdminLayoutRoutes().find(item => item.path === '/')
      return (routes && routes.children) || []
    },
    headerWidth() {
      if (this.isScreenXs) {
        return {
          width: '100%',
        }
      }
      return {
        width: this.collapsed ? 'calc(100% - 80px)' : `calc(100% - ${this.menuWidth})`,
      }
    },
  },
  created() {
    this.$watch('isScreenXs', () => {
      this.$store.commit('setMobileType', this.isScreenXs)
    })
  },
  mounted() {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
  },
  methods: {
    handleMediaQuery(val) {
      if (this.isScreenXs && !val['screen-xs']) {
        this.isScreenXs = false
        this.collapsed = false
        return
      }
      if (!this.isScreenXs && val['screen-xs']) {
        this.isScreenXs = true
        this.collapsed = true
      }
    },
    handleCollapse() {
      this.collapsed = !this.collapsed
    },
  },
}
</script>

<style lang="less" scoped>
.admin-layout {
  position: relative;
  height: 100%;
  &__trigger {
    height: 100%;
    &__logo {
      width: 100%;
      height: 64px;
      line-height: 64px;
      text-align: center;
      border-right: 1px solid #e8e8e8;
    }
    &__logo-collapsed {
      width: 34px;
    }
  }
  .layout-header {
    position: fixed;
    top: 0;
    z-index: 9;
    padding: 0;
    background: #fff;
  }
  .trigger-icon-box {
    text-align: center;
  }
  .trigger-icon {
    font-size: 20px;
  }
  .content {
    padding: 80px 24px 24px 24px;
  }
  &--no-header {
    /deep/ .ant-layout-header {
      display: none;
    }
  }
  /deep/ .ant-drawer-body {
    padding: 0 !important;
  }
  /deep/ .ant-pro-sider-menu-logo img {
    width: auto;
    height: 30px;
  }
  /deep/ .ant-pro-global-header {
    display: flex;
  }
  /deep/ .ant-pro-global-header-logo {
    padding: 0;
  }
  /deep/ .ant-pro-global-header-content {
    flex: 1;
  }
  /deep/ .ant-pro-basicLayout-content {
    padding: 16px;
    margin: 0;
    background: #f2f3f5;
  }
  /deep/ .ant-menu-item {
    a {
      color: #181e2d;
    }
  }
  /deep/ .ant-menu-item-selected {
    a {
      color: @primary-color;
    }
  }
  /deep/ .ant-menu-submenu {
    color: #181e2d;
  }
  /deep/ .ant-menu-submenu-selected {
    color: @primary-color;
  }
}
.sider-menus {
  border-top: 1px solid #e8e8e8;
  border-right: 0;
}
.layout-left {
  background: #fff;
}
</style>
<style lang="less"></style>
