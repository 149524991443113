var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account-layout"},[_c('a-row',{staticClass:"account-layout__row",attrs:{"type":"flex","justify":"center"}},[_c('a-col',{staticClass:"account-layout__left-content",attrs:{"xs":0,"md":0,"lg":14}},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[(_vm.isNeedLoadImg)?_c('img',{directives:[{name:"compress",rawName:"v-compress",value:({
            src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/account/logo-light.png',
          }),expression:"{\n            src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/account/logo-light.png',\n          }"}],staticClass:"account-layout__left-content__logo"}):_vm._e(),(_vm.isNeedLoadImg)?_c('img',{directives:[{name:"compress",rawName:"v-compress",value:({
            src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/account/logo-half.png',
          }),expression:"{\n            src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/account/logo-half.png',\n          }"}],staticClass:"account-layout__left-content__half-logo"}):_vm._e()]),_c('a-row',{staticClass:"account-layout__left-content__theme-row",attrs:{"type":"flex","justify":"center","align":"middle"}},[(_vm.isNeedLoadImg)?_c('img',{directives:[{name:"compress",rawName:"v-compress",value:({
            src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/account/theme-bg.png',
          }),expression:"{\n            src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/account/theme-bg.png',\n          }"}],staticClass:"account-layout__left-content__theme-img"}):_vm._e()])],1),_c('a-col',{staticClass:"account-layout__right-content",attrs:{"xs":24,"md":24,"lg":10}},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }