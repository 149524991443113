<template>
  <div class="customer-service">
    <a-icon
      v-if="visible"
      class="customer-service__icon"
      :class="{ 'customer-service__icon--home': isHomeForMobile }"
      :component="IconCustomerService"
    ></a-icon>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import IconCustomerService from '@/assets/icons/icon-customer-service.svg'
import { getWebConfig } from '@/api/common'
import { NATION_TYPE, LANGUAGE_TYPE } from '@/constants/common'
import { removeEmptyField } from '@/utils'
export default {
  name: 'CustomerService',
  components: {},
  data() {
    return {
      IconCustomerService,
      visible: false,
    }
  },
  computed: {
    ...mapState(['countryCode', 'language', 'userInfo', 'isScreenXs']),
    location() {
      return this.language === LANGUAGE_TYPE.ARABIC ? 2 : 1 // 1-右下角 2-左下角
    },
    containerStyle() {
      return this.language === LANGUAGE_TYPE.ARABIC ? 'left: 0;' : 'right: 0;'
    },
    isHomeForMobile() {
      return this.isScreenXs && this.$route.path === '/home'
    },
  },
  async mounted() {
    this.getWebConfig()
  },
  methods: {
    async getWebConfig() {
      const { userId, firstName, lastName, mail } = this.userInfo
      const params = {
        country: this.countryCode,
        userId,
        firstName,
        lastName,
        mail,
      }
      if (!userId) {
        params.userId = this.getCustomerServiceDid()
      }
      const userName = firstName && lastName ? `${firstName || ''} ${lastName || ''}` : 'visitor'
      this.customerServerUserInfo = {
        partnerid: params.userId,
        uname: userName,
        realname: userName,
        email: mail,
      }
      if (![NATION_TYPE.EGYPT, NATION_TYPE.NIGERIA].includes(this.countryCode)) {
        params.country = NATION_TYPE.NIGERIA
      }

      const { code, data } = await getWebConfig(removeEmptyField(params))
      if (code === 200 && data && data.imUrl) {
        this.initCustomerService(data.imUrl)
      }
    },
    initCustomerService(imUrl) {
      ;(function (w, d, e, x) {
        w[e] = function () {
          w.cbk = w.cbk || []
          w.cbk.push(arguments)
        }
        x = d.createElement('script')
        x.async = true
        x.id = 'zhichiScript'
        x.className = 'customer-service__icon'
        x.src = imUrl
        d.body.appendChild(x)
      })(window, document, 'zc')
      zc('config', {
        custom: true,
        async: true,
        expand_size: 'half',
        locale: this.locale,
        location: this.location,
        container_style: this.containerStyle,
        ...this.customerServerUserInfo,
      })
      zc('frame_ready', () => {
        this.visible = true
      })
    },
    createRandomId() {
      return (
        (Math.random() * 10000000).toString(16).slice(0, 4) +
        '-' +
        new Date().getTime() +
        '-' +
        Math.random().toString().slice(2, 7)
      )
    },
    getCustomerServiceDid() {
      const storage = window.sessionStorage
      let customerServiceDid = storage.getItem('customerServiceDid')
      if (customerServiceDid) {
        return customerServiceDid
      } else {
        customerServiceDid = this.createRandomId()
        storage.setItem('customerServiceDid', customerServiceDid)
        return customerServiceDid
      }
    },
  },
}
</script>
<style scoped lang="less">
.customer-service {
  &__icon {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 11;
    width: 60px;
    height: 60px;
    font-size: 60px;
    color: #fff;
    cursor: pointer;
    &--home {
      bottom: 65px;
    }
  }
}
</style>
