import { request, METHOD } from '@/utils'
// 获取博客分组列表
export const getBlogCategoryList = params => {
  return request('/saas-api/blog/v1/official/category/list', METHOD.POST, params)
}
// 查询博客列表
export const getBlogList = params => {
  return request('/saas-api/blog/v1/official/articles/list', METHOD.POST, params)
}
// 查询博客列表
export const getBlogDetail = params => {
  return request(
    `/saas-api/blog/v1/official/articles/get/${params.blogGroupId}`,
    METHOD.GET,
    params
  )
}
