import axios from 'axios'
import { message } from 'ant-design-vue'
import { isPandovaApp, JSBridge } from '@/utils'
import store from '@/store'
import router from '@/router/index'

// 请求方法MAP
const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
}

// 创建 axios 实例
const instance = axios.create({
  baseURL: '',
  timeout: 6000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// 请求拦截器
instance.interceptors.request.use(
  config => {
    const { accessToken, language, shopInfo } = store.state
    config.headers['Access-Token'] = accessToken || ''
    config.headers['Access-Language'] = language || ''
    config.headers['Shop-Id'] = (shopInfo || {}).shopId || ''
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  response => {
    const res = response.data || {}
    // 是否预处理业务错误
    const { needPreprocessBusinessError = true } = response.config
    // 999：Token非法，999：Token失效
    if ([999, 998].includes(res.code)) {
      store.commit('accessToken', '')
      store.commit('userInfo', {})
      store.commit('shopInfo', {})
      if (isPandovaApp()) {
        JSBridge.targetScheme({ url: 'pandova://account.login' })
      } else {
        router.push({ path: '/account/login' })
      }
      return Promise.reject(res)
    }
    if (needPreprocessBusinessError && res.code !== 200) {
      message.error(res.message || 'Error')
      // window.$sentry.log({
      //   errorInfo: { name: 'HttpBusinessError', message: 'HttpBusinessError' },
      //   extra: response,
      //   tagInfo: { key: 'api', value: response?.config?.url },
      // })
      return Promise.reject(res)
    }
    return res
  },
  error => {
    const { needPreprocessBusinessError = true } = error?.config || {}
    if (error.request.status === 500) {
      if (needPreprocessBusinessError) {
        message.error($t('common.serverErrorTip'))
        error.message = $t('common.serverErrorTip')
      }
      // window.$sentry.log({
      //   errorInfo: { name: 'HttpServerError', message: 'HttpServerError' },
      //   extra: error,
      //   tagInfo: { key: 'api', value: error?.config?.url },
      // })
    }
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      if (needPreprocessBusinessError) {
        message.error($t('common.networkTimeout'))
        error.message = $t('common.networkTimeout')
      }
      // window.$sentry.log({
      //   errorInfo: { name: 'HttpTimeoutError', message: 'HttpTimeoutError' },
      //   extra: error,
      //   tagInfo: { key: 'api', value: error?.config?.url },
      // })
    }
    return Promise.reject(error)
  }
)

// 请求
const request = (url, method, params, config) => {
  const methodActionMap = {
    [METHOD.GET]: () => instance.get(url, { params, ...config }),
    [METHOD.POST]: () => instance.post(url, params, config),
    [METHOD.PUT]: () => instance.put(url, params, config),
    [METHOD.DELETE]: () => instance.delete(url, { params, ...config }),
  }
  return methodActionMap[method]()
}

export { METHOD, request }
