import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import VueGtag from 'vue-gtag'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import VueClipboard from 'vue-clipboard2'
import i18n from '@/locales'
import router from '@/router'
import store from '@/store'
import App from '@/App.vue'
import bootstrap from '@/bootstrap'
import components from '@/components'
import directives from '@/directives'
import filters from '@/filters'
// import { isDevelopment, isProduction } from '@/utils'
// import OPaySentry from '@/utils/opay-sentry'
import { isDevelopment } from '@/utils'
import { getOssImageCompressUrl } from '@/utils/image-compress'
import Swiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import '@/components/ant-design-vue.js'
import '@/styles/common.less'
import '@/styles/antd.less'
Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg.includes('Duplicate keys detected') && isDevelopment()) {
    return false
  }
}
Vue.config.productionTip = false
Vue.use(Swiper)
Vue.use(VueLazyLoad, {
  filter: {
    progressive(listener, options) {
      listener.el.setAttribute('lazy-progressive', 'true')
      const compressOption = {
        src: listener.src,
        width: listener.el && listener.el.width,
      }
      listener.src = getOssImageCompressUrl(compressOption)
    },
  },
  preLoad: 1.3,
  attempt: 2,
  error: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/common/load-error.png',
  loading: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/common/loading.jpg',
})
Vue.use(
  VueGtag,
  {
    config: { id: 'G-EHM7YCNSWT' },
  },
  router
)
Vue.use(VueFacebookPixel, {})
Vue.analytics.fbq.init('2220739861417551')

Vue.use(VueClipboard)

// const sentry = OPaySentry.getInstance(Vue, {
//   isReport: isProduction() && process.env.NODE_ENV === 'production',
//   router,
//   environment: process.env.VUE_APP_ENV,
//   dsn: 'https://2600c41d1b2b4635b898516ca830fa44@sentry.opaydev.com/8',
//   release: process.env.VUE_APP_SENTRY_RELEASE,
//   ignoreErrors: [
//     'Network Error',
//     'ChunkLoadError',
//     'ReferenceError',
//     'RangeError',
//     'SecurityError',
//     'InternalError',
//     'Request aborted',
//     'Maximum call stack size exceeded',
//     'ResizeObserver loop limit exceeded',
//     'onPagePause is not defined',
//     'onPageResume is not defined',
//     'Load javascript failed',
//     'Request failed with status code 500',
//     'Request failed with status code 502',
//     'Unstable network connection. Please refresh and try again later.',
//     'Service exception. Please try again later.',
//     'timeout of 6000ms exceeded',
//     'Non-Error promise rejection captured with keys: code',
//     'Non-Error exception captured with keys: code',
//     'Avoided redundant navigation to current location',
//     /Cannot read property 'value' of null/,
//     /Cannot read property 'offsetTop' of null/,
//     /Loading CSS chunk \d+ failed/,
//     /Identifier 'isMainframe' has already been declared/,
//     /Cannot read properties of undefined \(reading '-'\)/,
//     /Cannot read properties of undefined \(reading 'matched'\)/,
//   ],
// })
// window.$sentry = sentry

components.forEach(component => {
  Vue.component(component.name, component)
})
directives.forEach(directive => {
  Vue.directive(directive.name, directive)
})
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

bootstrap()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

console.info('hotfix/hw-test')
