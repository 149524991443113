import { request, METHOD } from '@/utils'
// 获取商品分组列表
export const getProductGroupList = params => {
  return request('/saas-api/shop-goods/category/backstage/v1/page-query', METHOD.POST, params)
}
// 新增商品分组
export const updateProductGroup = params => {
  return request('/saas-api/shop-goods/category/backstage/v1/save-or-update', METHOD.POST, params)
}
// 删除商品分组
export const deleteProductGroup = params => {
  return request('/saas-api/shop-goods/category/backstage/v1/delete', METHOD.POST, params)
}
// 校验商品分组名称
export const checkProductGroupName = params => {
  return request('/saas-api/shop-goods/category/backstage/v1/check/duplicates', METHOD.POST, params)
}
// 获取商品列表
export const getProductList = params => {
  return request('/saas-api/shop-goods/goods/backstage/v1/page-query', METHOD.POST, params)
}
// 获取商品详情
export const getProductDetail = params => {
  return request(
    `/saas-api/shop-goods/goods/backstage/v1/query/goods/by-id/${params.id}`,
    METHOD.GET,
    params
  )
}
// 获取商品单位
export const getProductUnitList = params => {
  return request('/saas-api/bff/unAuth/v1/dict/MEASURE_UNIT/item', METHOD.GET, params)
}
// 获取商品重量
export const getProductWeightUnitList = params => {
  return request('/saas-api/bff/unAuth/v1/dict/GOODS_WEIGHT/item', METHOD.GET, params)
}
// 新增商品
export const updateProduct = params => {
  return request('/saas-api/shop-goods/goods/backstage/v1/save-or-update', METHOD.POST, params)
}
// 获取全量分组列表
export const getAllProductCategoryList = params => {
  return request('/saas-api/shop-goods/category/backstage/v1/list-query', METHOD.POST, params)
}
// 删除商品
export const deleteProduct = params => {
  return request('/saas-api/shop-goods/goods/backstage/v1/batch-delete', METHOD.POST, params)
}
// 批量上架下架商品
export const batchChangeUseStatus = params => {
  return request('/saas-api/shop-goods/goods/backstage/v1/batch/published', METHOD.POST, params)
}
// 校验导入商品的文件
export const checkProductExcel = params => {
  return request(
    '/saas-api/shop-goods/goods/backstage/v1/import/goods-info/verify',
    METHOD.POST,
    params,
    {
      timeout: 15000,
      needPreprocessBusinessError: false,
    }
  )
}
// 导入商品
export const importProduct = params => {
  return request('/saas-api/shop-goods/goods/backstage/v1/import/goods-info', METHOD.POST, params, {
    timeout: 15000,
    needPreprocessBusinessError: false,
  })
}
// 批量更新库存
export const batchUpdateStock = params => {
  return request('/saas-api/shop-goods/stock/backstage/v1/batch/update', METHOD.POST, params)
}
// 查询库存列表
export const getInventoryList = params => {
  return request('/saas-api/shop-goods/stock/backstage/v1/page-query', METHOD.POST, params)
}
// 查询商品列表
export const getShopGoodsList = params => {
  return request('/saas-api/shop-goods/goods/oshop/v1/page-query', METHOD.POST, params)
}
